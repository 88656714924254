import { useContractWrite } from "wagmi"
import { ContractAddress } from "../contracts/contractAddress"
import { ContractABI } from "../contracts/contractABI"
import { Anchor, Button } from "react95"

export const BuySimple = (props) => {

    let value = props.buyPrice
    let address = props.address

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'buyShares',
        args: [address, 1],
        value: value
      })    

// https://basescan.org/tx/0xbb783ec9f97646a8b256fd32bc601669399f844e35caa378d9bf22589c056494

    return (
        <div>
            <Button onClick={() => write()}>BUY</Button>
            {isSuccess && 
            <Anchor href={"https://basescan.org/tx/" + JSON.stringify(data)} target="_blank">Transaction 
            
            </Anchor>}



        </div>
    )
}
import axios from "axios"
import { useEffect, useState } from "react"
import { Anchor, AppBar, Avatar, Button, Frame, Hourglass, ScrollView, Separator, TextInput, Tooltip, Window, WindowContent, WindowHeader } from "react95"
import { useAccount } from "wagmi"
import { Message } from "../components/Message"
import { useStoreAuthKey } from "../hooks/useStoreAuthKey"
import { NotOwnedChat } from "./NotOwnedChat"

import va from '@vercel/analytics'
// {"action": "ping"} every 2 seconds to keep connection live

// get messages for a chat room
// {"action":"requestMessages","chatRoomId":"0x540473e9152b0c30a5b490f3518f2ae315e8ae6c","pageStart":null}	
// {"action":"requestMessages","chatRoomId":"0x34f4ed36d1df9c01c0e87dbe5f345833b499d7e0","pageStart":1694368266357}	- next page start

// sending messages

// get the portfolio - ["chatRoomId"] - this is what allows us to display the charts and last messages
// https://prod-api.kosetto.com/portfolio/0x3752Aa675e4B0D29835428B6b79B72ada0d2A987

// - [ ] need to setup a websocket connection 

export const Chat = () => {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    const { getAuthKey, setAuthKey, authKey, removeAuthKey } = useStoreAuthKey();
    const [updateAuthKeyState, setUpdateAuthKeyState] = useState()

    // check if authkey already defined
    if(authKey == undefined) {
        console.log("auth key is undefined")
    } 

    useEffect(() => {
        // set watchlist
        if(authKey == undefined) {
            console.log("auth key is undefined")
        } else {
            console.log(authKey)
        }
    },[updateAuthKeyState])

    // const [authKey, setAuthKey] = useState()
    const [portfolio, setPortfolio] = useState()
    const [messageReceived, setMessageReceived] = useState()

    const [ws, setWs] = useState(null)

    useEffect(() => {
        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/portfolio/" + address,
            headers: {
               "Authorization": authKey 
            }
        }).then(response => {
            console.log(response.data)
            let port_01 = response.data
            // here we need to check something


            setPortfolio(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[messageReceived, authKey])

    const [selectedUser, setSelectedUser] = useState("")    
    const [selectedUserRoomID, setSelectedUserRoomId] = useState("")
    const [messages, setMessages] = useState()

    //const ws = new WebSocket("wss://prod-api.kosetto.com/?authorization=" + authKey)
        

    const [websocketClosed, setWebsocketClosed] = useState(false)
    
    useEffect(() => {
        const wsClient = new WebSocket("wss://prod-api.kosetto.com/?authorization=" + authKey);
        wsClient.onopen = () => {
          setWs(wsClient);
          setWebsocketClosed(false)
        };
        wsClient.onclose = () => {
            console.log('ws closed')
            setWebsocketClosed(true)
        return () => {
          wsClient.close();
        };}
      }, [authKey, websocketClosed]);
    

    const [loading, setLoading] = useState(false)
    const clickButton = (user, roomID) => {
        console.log("CLICKED: " + user + " " + roomID)
        setLoading(true)
        try {
        ws.send(JSON.stringify({ 
            "action":"requestMessages",
            "chatRoomId":roomID,
            "pageStart":null }
        ))
        setSelectedUserRoomId(roomID)
        setSelectedUser(user)

    } catch {
        console.log("Smol Error")
        }      
    }

      useEffect(() => {
        if (ws) {
            ws.onmessage = function (event) {
                try {
                    const json = JSON.parse(event.data);
                    if(json["type"] == "pong") {
                        console("pong")
                    } else if (json["type"] == 'messages') {
                        console.log(json)
                        setMessages(json["messages"])

                        setLoading(false)

                    //ws.onmessag
                    } else if (json["type"] == "receivedMessage") {
                        setMessageReceived(json)
                        console.log(json)
                    } else {
                        console.log(json)
                    }
                } catch (err)  {
                    console.log(err)
                    // console.log(err)
                }   
              };
        }
      }, [ws, authKey]);
    
      const removeDuplicates = (array) => {
        return([...new Set(array)])
      }

      const updateMessages = (json) => {
        console.log(json["chatRoomId"])
        console.log(selectedUserRoomID)
        
        if(json["chatRoomId"] === selectedUserRoomID) {
            console.log("new message in current chat room")
            setMessages([json, ...messages])

        } else {
            console.log("no match")
        }
      }

    // PINGING 
    useEffect(() => {
        if (ws) {
            const interval = setInterval(() => {
                ws.send(JSON.stringify({ "action": "ping" }))
            }, 2000)
            return () => clearInterval(interval)
        }},[ws,authKey])
        
    useEffect(() => {
            if (ws) {
                try {
               //     ws.send(JSON.stringify({ 
               //         "action":"requestMessages",
               //         "chatRoomId":selectedUserRoomID,
               //         "pageStart":null }
               //     ))
               updateMessages(messageReceived)

                } catch {
                    console.log("Smol Error")
                    }      

            }},[messageReceived])

    const [messageToSend, setMessageToSend] = useState("")

    const handleMessageToSend = (event) => {
        const {value,name} = event.target
        setMessageToSend(value)
    }

    const sendMessageButton = (roomID, username, replyInTo) => {
        try {
            ws.send(JSON.stringify({ 
                "action":"sendMessage",
                "text": messageToSend,
                "imagePaths": [],
                "chatRoomId":selectedUserRoomID,
                "clientMessageId": "87e8a533935",
                "replyingToMessageId": replyInTo
                }
            ))
            va.track('msg sent')

        } catch {
            console.log("Smol Error")
        }      
    
    // keep username
    setSelectedUser(username)
    // keep room ID
    console.log("ROOM IDD " + roomID)
    setSelectedUserRoomId(roomID)

    }
 // {"action":"sendMessage","text":"\"Chart Interface may be interesting\"","imagePaths":[],"chatRoomId":"0x3752aa675e4b0d29835428b6b79b72ada0d2a987","clientMessageId":"87e8a533935","replyingToMessageId":3399408}	1694511788.0306032

 const [writingAuthKey, setWritingAuthKey] = useState("")

 const handleWritingAuthKey = (event) => {
     const {value,name} = event.target
     setWritingAuthKey(value)
 }

 const submitAuthKey = () => {
    console.log("submitting auth key")
    setAuthKey(writingAuthKey)
    setUpdateAuthKeyState(1)
    alert("auth key submitted. Reload the site.")
 }

    // the message to reply to by ID - then text
    const [selectedMessageToReply, setSelectedMessageToReply] = useState(0)
    const [owningRoom, setOwningRoom] = useState(false)

    useEffect(() => {
        if(address.toUpperCase() === selectedUserRoomID.toUpperCase()) {
            setOwningRoom(true)
        } else {
            setOwningRoom(false)
        }
    },[selectedUserRoomID])

    const autogm = (msg) => {
        // send every user in the portfolio a message 

        portfolio["holdings"].slice(0,25).map(holdings => {

        // restrict messages to latest 25 accounts
        // to make sure that people don't get rate-limited

            console.log(holdings)
            try {
                ws.send(JSON.stringify({ 
                    "action":"sendMessage",
                    "text": msg.toString(),
                    "imagePaths": [],
                    "chatRoomId":holdings["chatRoomId"],
                    "clientMessageId": "87e8a533935",
                    "replyingToMessageId": 0
                    }
                ))
                va.track('auto msg')

        
            } catch (err) {
                console.log(err)
            }      
        })
    }

    const removeAuthKeyClick = () => {
        removeAuthKey()
        alert('removed auth key from storage, reload site.')
    }

    const [customMsg, setCustomMsg] = useState("")

    const handleCustomMsg = (event) => {
        const {value,name} = event.target
        setCustomMsg(value)
    }

    if(authKey == undefined) {
        return(
                <div className="Main container">
                    <center>
                    <Window className="medSize">    
                        <WindowHeader>Auth.exe</WindowHeader>

                        <p>Enter your authorization key. Thread explaining how: <Anchor href="https://twitter.com/0xsubmerged/status/1701712269611077978" target="_blank">Thread</Anchor></p>
                        <br></br>

                        <TextInput
                        value={writingAuthKey}          
                        placeholder='Paste in your Auth Key ...'
                        onChange={handleWritingAuthKey}
                        />
                        <br></br>

                        <Button onClick={submitAuthKey}>Submit</Button>

                    </Window>
                    </center>   
                </div>
        )
    } else {
    return(
        <div className="Main container">
        <Window className="fullSize">
            <WindowHeader>FrentechIM.exe</WindowHeader>
            <WindowContent>

            {portfolio != undefined ? (
                            <div className="row">
                            <div className="col-sm-3 fullSize">

                            <img className="FIM" src={"/frentechIM.png"}/>                            
                            

                            <Frame variant='inside' style={{ padding: '1rem',width: '100%'  }}>
                            <p className="smallGray">Quick Tools</p>
                            
                            <Tooltip text='say gm in your latest 25 chats' enterDelay={100} leaveDelay={500}>
                                <Button onClick={() => autogm("gm")}>Auto GM</Button>
                            </Tooltip>
                            <Button onClick={() => removeAuthKeyClick()}>Remove Auth</Button>


                            </Frame>

                            <Frame variant='inside' style={{ padding: '1rem',width: '100%'  }}>
                            <p className="smallGray">Custom Message</p>
                            <TextInput
                                            value={customMsg}          
                                            placeholder='Message ...'
                                            onChange={handleCustomMsg}
                                            />
                            <Tooltip text='send to latest 25 rooms' enterDelay={100} leaveDelay={500}>
                                <Button onClick={() => autogm(customMsg)}>Send</Button>
                            </Tooltip>


                            </Frame>
                            
                            <br></br>
                            <br></br>

                                {portfolio["holdings"].map(hold => {
                                    return(
                                        <Button onClick={() => clickButton(hold["username"], hold["chatRoomId"])} fullWidth>
                                            <img src={hold["pfpUrl"]} width="15em" className="imageSmol"/>
                                            <p>{hold["username"]}</p>
                                        </Button>
                                    )
                                })}
            
                            </div>
                            <div className="col-sm-9">

                                    <div>
                                {selectedUser == undefined || messages ==undefined  ? (
                                    <div>

                                    {loading ? (
                                        <Hourglass size={32} style={{ margin: 20 }} />
                                    ) : (
                                        <p>Please select user</p>
                                    )}
                                    </div>
                                ) : (
                                
                                    <div>

                                {owningRoom == true ? (
                                        <div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                            <TextInput
                                            value={messageToSend}          
                                            placeholder='Send Message ...'
                                            onChange={handleMessageToSend}
                                            />
                                            

                                            {selectedMessageToReply === 0 ? (
                                                <></>
                                            ) : (
                                                <div className="row">
                                                <div className="col-sm-10">
                                                    <span className="smallGray">Replying to: </span>
                                                    <img src={selectedMessageToReply["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                                    <span className="smallGray">{selectedMessageToReply["twitterName"]}: </span>
                                                    <p className="replyText">{selectedMessageToReply["text"]}</p>
                                                </div>   
                                                <div className="col-sm-2">
                                                    <br></br>
                                                    <Button size="sm" onClick={() => setSelectedMessageToReply(0)} className="right">X</Button>
                                                </div>   
                                            </div>

                                            )}
                                            </div> 
                                            <div className="col-sm-2">
                                                <Button onClick={() => sendMessageButton(selectedUserRoomID, selectedUser, selectedMessageToReply["messageId"])}>Send</Button>
                                            </div> 



                                        
                                        </div>

                                        {loading ? (
                                            <Hourglass size={32} style={{ margin: 20 }} />
                                        ) : (
                                            <ScrollView style={{ height: '60rem' }}>
                                            {messages.map(function(msg) {
                                                return(<Message setSelectedMessageToReply={setSelectedMessageToReply} selectedUserRoomID={selectedUserRoomID} data={msg} />)
                                            })}
                                            </ScrollView>

)}
                                        </div>

                                    ) : (

                                        <div>
                                        <div className="row">
                                            <div className="col-sm-10">
                                            <TextInput
                                            value={messageToSend}          
                                            placeholder='Send Message ...'
                                            onChange={handleMessageToSend}
                                            />
                                            

                                            {selectedMessageToReply === 0 ? (
                                                <></>
                                            ) : (
                                                <div className="row">
                                                <div className="col-sm-10">
                                                    <span className="smallGray">Replying to: </span>
                                                    <img src={selectedMessageToReply["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                                    <span className="smallGray">{selectedMessageToReply["twitterName"]}: </span>
                                                    <p className="replyText">{selectedMessageToReply["text"]}</p>
                                                </div>   
                                                <div className="col-sm-2">
                                                    <br></br>
                                                    <Button size="sm" onClick={() => setSelectedMessageToReply(0)} className="right">X</Button>
                                                </div>   
                                            </div>

                                            )}
                                            </div> 
                                            <div className="col-sm-2">
                                                <Button onClick={() => sendMessageButton(selectedUserRoomID, selectedUser, selectedMessageToReply["messageId"])}>Send</Button>
                                            </div> 



                                        
                                        </div>

                                        {loading ? (
                                            <Hourglass size={32} style={{ margin: 20 }} />
                                        ) : (
                                            <ScrollView style={{ height: "60rem" }}>
                                            {messages.map(function(msg) {
                                                return(<NotOwnedChat setSelectedMessageToReply={setSelectedMessageToReply} selectedUserRoomID={selectedUserRoomID} data={msg} />)
                                            })}
                                        </ScrollView>
                                        )}


                                        </div>
)}
                                    </div>
                                )}
                                    </div>
                                

                                    <div className="right">{websocketClosed == false ? (
                    <p className="darkerGreen">Connected</p>
            ) : (
                <p className="orange">Reconnecting</p>
            )}</div>

            
                            </div>
                        </div>
            ) : (
                <div>
                    <Hourglass size={32} style={{ margin: 20 }} />
                    <p>If it keeps loading for too long, the key may be invalid.</p>
                    <Button onClick={() => removeAuthKeyClick()}>Remove Auth Key</Button>
                </div>
            )}

            </WindowContent>

        </Window>

    </div>
    )
}}
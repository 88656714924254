import moment from "moment"
import { Button, Separator } from "react95"
import { useAccount } from "wagmi"

export const Message = (props) => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    // check if message is being sent in the owners room
    let data = props.data
    let selectedUserRoomID = props.data

    const selectReply = (messageID) => {
        props.setSelectedMessageToReply(messageID)
    }

      let timeMessageWasSent = data["timestamp"]
      let newTime = moment(timeMessageWasSent).fromNow()

    return(
        <div>
            {data["replyingToMessage"] ? (
                <div className="repliedText">
                    <span className="smallGray">Replying to: </span>
                    <img src={data["replyingToMessage"]["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                    <span className="smallGray">{data["replyingToMessage"]["twitterName"]}: </span>
                    <span className="smallGray">{newTime}</span>

                    <p className="replyText">{data["replyingToMessage"]["text"]}</p>
                    
                </div>
            ) : (
                <></>
            )}

            <div className="userMessage">

            {data["sendingUserId"].toUpperCase() === address.toUpperCase() ? (
                <>
                </>
            ) : (
                <div className="reply">
                    <Button onClick={() => selectReply(data)} size="sm">Reply</Button>
                </div>
            )}

            <img src={data["twitterPfpUrl"]} width="15em" className="imageSmol"/>
            <span className="smallGray">{data["twitterName"]}: </span>
            
            <span className="smallGray">{newTime}</span>

            <span></span>

            <p>{data["text"]}</p>
            <p className="smallGray">Ready by: {data["readByCount"]}</p>
            <img src={data[""]}/>
            {data["imageUrls"].map((image) => {
                return(
                <img className="imageMessage" src={image}/>
                )
            })}
            </div>
            <Separator />

            


        </div>
    )

}
import { ActivityForAddress } from "../components/ActivityForAddress"
import { RecentlyJoined } from "../components/RecentlyJoined"
import { Trending } from "../components/Trending"

export const Data = () => {

    return(
        <div className="Main">

        <div className="row">
            <div className="col-sm-4">
                <Trending />
            </div>
            <div className="col-sm-4">
                <RecentlyJoined />
            </div>
            <div className="col-sm-4">
                <ActivityForAddress />
            </div>
        </div>


        
        </div>
    )
}
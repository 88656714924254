import { Button, TextInput, Tooltip, Window, WindowHeader } from "react95"
import { WrappedSharesABI } from "../contracts/wrappedSharesABI"
import { WrappeWrappedSharesABIdSharesAddress, WrappedSharesAddress } from "../contracts/wrapSharesAddress"
import { useContractWrite } from "wagmi"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useState } from "react"

// basically just have to call the contract and the mint function


export const Wrap = () => {

    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState("")

    const handleAddressChange = (event) => {
        const {value,name} = event.target
        setAddress(value)
    }

    const handleAmountChange = (event) => {
        const {value,name} = event.target
        setAmount(value)
    }

    let value1 = (useGetPriceOfAddress(address) * amount)
    let value2 = (value1 + (value1 * 0.1)).toString()
    console.log(value1)

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: WrappedSharesAddress,
        abi: WrappedSharesABI,
        functionName: 'mint',
        args: [address, amount],
        value: value2
      })    


    return(
        <center className="Main">
            <Window>
                <WindowHeader>Wrap Shares</WindowHeader>

            <TextInput
            value={address}          
            placeholder='Paste Address...'
            onChange={handleAddressChange}
            />

            <TextInput
            value={amount}          
            placeholder='Enter Amount...'
            onChange={handleAmountChange}
            />

<br></br>

        <Tooltip text='Wrap Share' enterDelay={100} leaveDelay={500}>
                <Button onClick={() => write()}>Wrap</Button>
            </Tooltip>

{isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}


            </Window>
        </center>
    )
}
// https://prod-api.kosetto.com/users/0xa71e78cb48d5631f3ad3013fefeb44794525fa89/token/trade-activity

import axios from "axios"
import { useEffect, useState } from "react"
import { API } from "../contracts/API"
import { Anchor, Button, ScrollView } from "react95"
import { formatEther } from "viem"
import moment from "moment"

export const ActivityFromAddress = (props) => {

    const [activity, setActivity] = useState()
    let address = props.address
    let price = props.price
    
    const refreshTrades = () => {
        axios({
            method: 'get',
            url: API + "get_recent_trades_for/" + address
        }).then(response => {
            console.log(response.data)
            setActivity(response.data)
        }).catch(error => {
            console.log(error)
        })

    }

    useEffect(() => {
        console.log("refreshingg")
        axios({
            method: 'get',
            url: API + "get_recent_trades_for/" + address
        }).then(response => {
            console.log(response.data)
            setActivity(response.data)
        }).catch(error => {
            console.log(error)
        })

    },[address, price])

    if(activity != undefined){
        return(

            <div>
            <Button onClick={refreshTrades}>Refresh</Button>

            <ScrollView style={{ height: '10rem' }}>

            <ul>

                {activity["users"].map(user => {
                    return(
                        <li>
                            <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                            {user["twitterUsername"]}
                            </Anchor>

                            {user['isBuy'] == true ? (
                                <span className="darkgreen"> Bought</span>
                            ) : (
                                <span> Sold</span>
                            )}

                            <span> {user['shareAmount']} </span>
                            <span className=" right smallGray"> {moment(Number(user["createdAt"])).fromNow()}</span> <span className="right"> {formatEther(user["ethAmount"])} ETH - </span>
                        </li>
                    )
                })}

            </ul>
            </ScrollView>

            </div>

        )
    } else {
        return(
            <div>
                <p>Loading...</p>
            </div>
        )
    }

    
}
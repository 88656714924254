import { useState } from "react";
import { Tab, TabBody, Table, TableBody, TableHead, TableHeadCell, TableRow, Tabs, Window, WindowContent, WindowHeader } from "react95";
import { PriceCell } from "../components/PriceCell";

export const PriceFeeds = () => {

    const [state, setState] = useState({
        activeTab: 0
        });
        
        const handleChange = (
                value,
                event
              ) => {
                console.log({ value, event });
                setState({ activeTab: value });
              };
            
        const { activeTab } = state;
              
// use this call to get info using only address of user
// https://prod-api.kosetto.com/users/0x3752Aa675e4B0D29835428B6b79B72ada0d2A987

    return(
        <Window className="Main prices longWindow">
            <WindowHeader>Price Feeds</WindowHeader>
                <Tabs value={activeTab} onChange={handleChange}>
                    <Tab value={0}>Crypto Twitter</Tab>
                    <Tab value={1}>E-Girls/Onlyfans</Tab>
                </Tabs>
                <TabBody style={{height: 300}} className="tabBody">

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeadCell>NAME</TableHeadCell>
                            <TableHeadCell>BUY</TableHeadCell>
                            <TableHeadCell>SELL</TableHeadCell>
                            <TableHeadCell>BALANCE</TableHeadCell>
                            <TableHeadCell>COMMANDS</TableHeadCell>
                            <TableHeadCell>CHANGE (12H)</TableHeadCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>

                    {activeTab === 0 && (
                        <>
                            <PriceCell address={"0x3752Aa675e4B0D29835428B6b79B72ada0d2A987"} />
                            <PriceCell address={"0xf0a6710c837757b91a1cd21f61ee75ff7a2ff969"} />
                            <PriceCell address={"0x88b7ab321dcd42751a5cdc616be79383c77e03d3"} />
                            <PriceCell address={"0x4e5f7e4a774bd30b9bdca7eb84ce3681a71676e1"} />
                            <PriceCell address={"0x5c46673dB2fA044f5aFC56c5324CE02F5B74e809"} />

                        </>

                    )}
                    {activeTab === 1 && (
                        <>
                            <PriceCell address={"0xc84566c5dfe863b7f0d16d340c6b0449b4e3b7c3"} />
                            <PriceCell address={"0xa4704d6b1d9b233db75a4b1047a8f7f8f80c6190"} />
                            <PriceCell address={"0x053d202b8d947d702e32c21795bfba50a44d238e"} />
                            <PriceCell address={"0xd03cc41fdcfbd497e635c1bbdfd69def64c9efe2"} />
                            <PriceCell address={"0xba2426fa663455806d4b34ea71876df2e20e561a"} />
                            <PriceCell address={"0xd6c7704d0e2387a9903d3fda2c5bcc0a74574280"} />
                            <PriceCell address={"0xd586c662938f850cfab7af6926f9539d44f4efce"} />
                            <PriceCell address={"0xe33908e59ff08b9f4c4cc07e3f186ef3a4e374c3"} />
                            <PriceCell address={"0x67dabae7f73e9c14968c729d1ee5a9f181c8c264"} />
                            <PriceCell address={"0xc4ecda10ba126537f336a03e637ba27fa144e71a"} />
                            <PriceCell address={"0x7ac223c1596bd32f195cb14447444c919e878657"} />
                            <PriceCell address={"0xde1affde1f9e5d7b5b4b7e7b954bc449270262a4"} />
                            <PriceCell address={"0x60760b00de83c2723dc753fa0e9eae7958567609"} />
                            <PriceCell address={"0xa26446f545d1f17ffee870fbb38fc0747c613752"} />
                            <PriceCell address={"0xa1f289dae28c9a614380e6bef4bca8fcf372a7c7"} />
                            <PriceCell address={"0xcfb39e25d2ed26af424500ebe2ff50ceb76a3deb"} />                            
                        </>
                    )}

                    </TableBody>
                    </Table>

                </TabBody>
                
        </Window>
    )
}
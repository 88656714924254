// gets the price of a share in eth, converted from wei
// need to call the following function - getBuyPriceAfterFee

import { useContractRead } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";
import { formatGwei, formatEther } from 'viem'


export const useGetSellPriceOfAddressWithAmount = (address,amount) => {
    
    const { data, isError, isLoading, error } = useContractRead({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'getSellPriceAfterFee',
        args: [address, amount],
        watch: true
      })

      return Number(data)

}
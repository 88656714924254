// buyShares
// input field

import { useState } from "react";
import { useContractRead, useContractWrite } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress";
import { Button, TextInput, Tooltip, Window } from "react95";

export const SellShareFromAddress = () => {
    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState("")

    
    const handleAddressChange = (event) => {
        const {value,name} = event.target
        setAddress(value)
    }

        // this is when the user wants to buy
      //   const { data, isError, isLoading, error } = useContractWrite({
      //       address: ContractAddress,
      //       abi: ContractABI,
      //       functionName: 'seeds',
      //       args: [address, amount]
      //     })

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'sellShares',
        args: [address, 1]
      })
    

    return(
        <Window className="widget col-sm-6">
            
            <TextInput
            value={address}          
            placeholder='Paste Address...'
            onChange={handleAddressChange}
            />
            
            <br></br>
            
            <Tooltip text='Sell 1 Share' enterDelay={100} leaveDelay={500}>
                <Button primary onClick={() => write()}>Sell</Button>
            </Tooltip>

            {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}

        </Window>
    )



}
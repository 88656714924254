import axios from "axios";
import { useEffect, useState } from "react";
import { Anchor, Button, ScrollView, Tab, TabBody, Tabs } from "react95";
import { formatEther } from "viem";

export const TopAccounts = (props) => {

    const [state, setState] = useState({
        activeTab: 0
    }); 

    const handleChange = (
        value,
        event
      ) => {
        console.log({ value, event });
        setState({ activeTab: value });
      };

    const { activeTab } = state;          

    // get top users https://prod-api.kosetto.com/lists/top-by-price
    // get trending users
    // get online users https://prod-api.kosetto.com/lists/online
    // recent messages https://prod-api.kosetto.com/lists/recent-messagers

    const [topUsers, setTopUsers] = useState()
    const [trendingUsers, setTrendingUsers] = useState()
    const [onlineUsers, setOnlineUsers] = useState()
    const [recentMessages, setRecentMessages] = useState()


    const [loading, setLoading] = useState(false)
    useEffect(() => {

        setLoading(true)

        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/lists/trending"
        }).then(response => {
            console.log(response.data)
            setTrendingUsers(response.data)
        }).catch(error => {
            console.log(error)
        })

        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/lists/top-by-price"
        }).then(response => {
            console.log(response.data)
            setTopUsers(response.data)
        }).catch(error => {
            console.log(error)
        })

        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/lists/online"
        }).then(response => {
            console.log(response.data)
            setOnlineUsers(response.data)
        }).catch(error => {
            console.log(error)
        })
    
        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/lists/recent-messagers"
        }).then(response => {
            console.log(response.data)
            setRecentMessages(response.data)
        }).catch(error => {
            console.log(error)
        })

        setLoading(false)

    },[])

    if(topUsers != undefined) {

    return(
        <div>
            <Tabs value={activeTab} onChange={handleChange}>
                <Tab value={0}>Top</Tab>
                <Tab value={1}>Trending</Tab>
                <Tab value={2}>Online</Tab>
                <Tab value={3}>Recent Messages</Tab>
            </Tabs>
            <TabBody>
                <ScrollView style={{ height: '12rem' }}>

                    {activeTab === 0 && (
                            <ul>
                                                {topUsers['users'].map(user => {
                                                    return(
                                                        <li>
                                                            <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                                                {user["twitterUsername"]} ({user["twitterUsername"]})
                                                            </Anchor>
                    
                                                            <Button onClick={() => props.setSelectedUser(user["address"])} className="miniButton" size="sm">Select</Button>
                    
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                    
                    )}

                    {activeTab === 1 && (
                        <ul>
                            {trendingUsers['users'].map(user => {
                                return(
                                    <li>
                                        <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                        <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                            {user["twitterUsername"]} ({user["twitterUsername"]})
                                        </Anchor>

                                        <Button onClick={() => props.setSelectedUser(user["address"])} className="miniButton" size="sm">Select</Button>
                                        <span className="right">15m Volume: {formatEther(user["volume"])} ETH</span>

                                    </li>
                                )
                            })}
                        </ul>
                    )}

                    {activeTab === 2 && (
                                                <ul>
                                                {onlineUsers['users'].map(user => {
                                                    return(
                                                        <li>
                                                            <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                                                {user["twitterUsername"]} ({user["twitterUsername"]})
                                                            </Anchor>
                    
                                                            <Button onClick={() => props.setSelectedUser(user["address"])} className="miniButton" size="sm">Select</Button>
                    
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                    )}

{activeTab === 3 && (
                                                <ul>
                                                {recentMessages['users'].map(user => {
                                                    return(
                                                        <li>
                                                            <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                                                {user["twitterUsername"]} ({user["twitterUsername"]})
                                                            </Anchor>
                    
                                                            <Button onClick={() => props.setSelectedUser(user["address"])} className="miniButton" size="sm">Select</Button>
                    
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                    )}                

                </ScrollView>

            </TabBody>
        </div>
    )
} else {
    <p>Loading</p>
}} 
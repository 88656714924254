import { GroupBox } from "react95"
import { formatEther } from "viem"


export const ActivityItem = (props) => {

    let item = props.item
    console.log(item)

    return(
        <GroupBox>

            { item['isBuy']== true ? (
                <center>
                    <p><img className="imageSmall" src={item['trader']['pfpUrl']} /> {item['trader']['name']}</p>
                    <p>Bought ({formatEther(item['ethAmount'])} ETH)</p>
                    <p><img className="imageSmall" src={item['subject']['pfpUrl']} />{item['subject']['name']}Shares</p>

                </center>

            ) : (
                <center>
                    <p><img className="imageSmall" src={item['trader']['pfpUrl']} /> {item['trader']['name']}</p>
                    <p>Sold ({formatEther(item['ethAmount'])} ETH)</p>
                    <p><img className="imageSmall" src={item['subject']['pfpUrl']} />{item['subject']['name']}Shares</p>
                </center>
            )}


        </GroupBox>
    )
}
// displays the price of a friend share

import { Window } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { PriceOfShare } from "./PriceOfShare"

export const PriceModal = () => {

    return(
        <div className="pricemodal row">

            <Window className="col-sm-4">
                <p>Brypto</p>
                <PriceOfShare className="col-sm-3" name={"Brypto"} address={"0xf0a6710c837757b91a1cd21f61ee75ff7a2ff969"} />
            </Window>
            
            <Window className="col-sm-4">
            <p>DirtyGod</p>
            <PriceOfShare  name={"DirtyGod"} address={"0xCD5ef33699fb0AE6025E187D07E205f9982BE19d"} />
            </Window>
            
            <Window className="col-sm-4">
            <p>Submerged</p>
            <PriceOfShare name={"Submerged"} address={"0x3752Aa675e4B0D29835428B6b79B72ada0d2A987"} />
            </Window>

            <Window className="col-sm-4">
            <p>AceDaBook</p>
            <PriceOfShare name={"AceDaBook"} address={"0x88b7ab321dcd42751a5cdc616be79383c77e03d3"} />
            </Window>

            <Window className="col-sm-4">
            <p>NaniXBT</p>
            <PriceOfShare name={"NaniXBT"} address={"0x43e02f3d25dc5f6154ccb1a8efd8aeb027a8691a"} />
            </Window>
            
            <Window className="col-sm-4">
            <p>Gains</p>
            <PriceOfShare name={"Gains"} address={"0x5c46673dB2fA044f5aFC56c5324CE02F5B74e809"} />
            </Window>

            <Window className="col-sm-4">
            <p>Cobie</p>
            <PriceOfShare name={"Cobie"} address={"0x4e5f7e4a774bd30b9bdca7eb84ce3681a71676e1"} />
            </Window>


            
        </div>
    )
}
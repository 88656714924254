import { useState } from "react"
import { TextInput } from "react95"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { ContractBurnAddress } from "../contracts/contractBurnAddress"

export const AmountOfSharesBurned = () => {

    const [address, setAddress] = useState("")

    const handleAddressChange = (event) => {
        const {value,name} = event.target
        setAddress(value)
    }

    let balance = useGetBalanceOfWallet(address, ContractBurnAddress)

    return(
        <div>
            <TextInput
                value={address}          
                placeholder='Enter Address...'
                onChange={handleAddressChange}
                />

        <p>Total Burned Shares</p>
        <p>{balance}</p>
        </div>
    )


}
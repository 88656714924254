import { useAccount, useBlockNumber, useContractRead } from "wagmi"
import { useGetCurrentBlock } from "./useGetBlock"
import { ContractAddress } from "../contracts/contractAddress"
import { ContractABI } from "../contracts/contractABI"
import { bytesToBigint, formatBlock, formatEther } from "viem"
import { useGetPriceOfAddress } from "./useGetPriceOfAddress"

export const useGetPastPerformance = (targetAddress, blocksToCountBack) => {

    // get current Block Number
    let currentBlock = useGetCurrentBlock()
    let currentPrice = useGetPriceOfAddress(targetAddress)


    const { data, isError, isLoading } = useContractRead({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'getBuyPrice',
        args: [targetAddress, 1],
        blockNumber: Number(currentBlock) - blocksToCountBack,
      })

      console.log("BLOOOOKS TO GO BACKKKK")
      console.log(Number(currentBlock) - blocksToCountBack)
      console.log(Number(currentBlock))
      console.log(Number(data))

  
      
      if(data != undefined) {
        // compare the 2 prices and calculate the percentage difference
        let priceDifference = Number(currentPrice) - Number(data)

        return(priceDifference)
} else {
    return("loading")
}}
// gets the price of a share in eth, converted from wei
// need to call the following function - getBuyPriceAfterFee

import { useContractRead } from "wagmi";
import { formatGwei, formatEther } from 'viem'
import { wordAddress } from "../contracts/wordAddress";
import { wordABI } from "../contracts/wordABI";

export const useGetWordSellPriceOfAddress = (address) => {
    
    const { data, isError, isLoading, error } = useContractRead({
        address: wordAddress,
        abi: wordABI,
        functionName: 'getSellPriceAfterFee',
        args: [address, 1],
        watch: true
      })


      return Number(data)

}
import { formatEther } from "viem"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { useAccount } from "wagmi"
import { BuySimple } from "./BuySimple"
import { SellSimple } from "./SellSimple"
import { Button } from "react95"
import { useWatchlist } from "../hooks/useWatchlist"
import { ContractBurnAddress } from "../contracts/contractBurnAddress"

export const PriceOfShare = (props) => {


    const Contractaddress = props.address
    const name = props.name

    let priceRaw = useGetPriceOfAddress(Contractaddress)
    let price = formatEther(priceRaw)
    let sellPrice = formatEther(useGetSellPriceOfAddress(Contractaddress))

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    let balance = useGetBalanceOfWallet(Contractaddress, address)

    let value = balance * sellPrice

    let balanceBurned = useGetBalanceOfWallet(Contractaddress,ContractBurnAddress)


    return(
    <div className="widget">

    <div className="row">
        <div className="col-sm-6 blue">
            <p>Buy Price</p>
            <span>{price}</span>
            <BuySimple className="col-sm-6" address={Contractaddress} buyPrice={priceRaw.toString()} />

        </div>
        <div className="col-sm-6 red">
            <p>Sell Price</p>
            <span>{sellPrice}</span>
            <SellSimple className="col-sm-6" address={Contractaddress}  />

        </div>
    </div>

    <p>contract: {Contractaddress.slice(0,8)}...</p>
    <p>Balance: {balance} <span className="green">{value} Ξ</span></p>
    <p>🔥 Burned Shares: {balanceBurned}</p>
    <p><a target="_blank" href={"https://dune.com/domo/friendtech?friend_t0f7de=" + Contractaddress + "&"}>Chart</a></p>
    <Button onClick={() => {navigator.clipboard.writeText(Contractaddress)}}>Copy Address</Button>

    </div>
    )

}
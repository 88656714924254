import { CipherTrade } from "../components/Cipher/CipherTrade"

export const Cipher = () => {

    let subject = "0x23D22005E44Fd56dC85F9cDBD32527C02dc5DA64"

    return(
        <div className="Main">

            <div className="row">
                <div className="col-sm-5">

                </div>
                <div className="col-sm-3">
                    <CipherTrade subject={subject} />
                </div>

            </div>
            
        </div>
    )
}
import { useContractRead, useContractWrite } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";

export const useGetBalanceOfWallet = (shareAddress, userAddress) => {

    const { data, isError, isLoading, error } = useContractRead({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'sharesBalance',
        args: [shareAddress,userAddress],
        watch: true,
        chainId: 84531
      })

      return Number(data)


}
import { BuyShareFromAddress } from "../components/BuyShareFromAddress"
import { PriceModal } from "../components/PriceModal"
import { SearchForUser } from "../components/SearchForUser"
import { SellShareFromAddress } from "../components/SellShareFromAddress"
import { SimplePortfolio } from "../components/SimplePortfolio"
import { Trending } from "../components/Trending"
import { Watchlist } from "../components/Watchlist"

export const Main = () => {

    return(
        <div>
        <div className='Main'>
            <center>
                <div className="col-sm-6">
                    <BuyShareFromAddress />
                    <SellShareFromAddress />
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <Watchlist />
                        </div>
                        <div className="col-sm-6">
                            <SimplePortfolio />
                        </div>

                    </div>

            </center>
        </div>

    <div className="row">
        <div className="col-sm-9">
        <SearchForUser />
        </div>
        <div className="col-sm-3">
            <Trending />
        </div>

        <PriceModal />
</div>
    </div>

    )
}
import { useContractRead, useContractWrite } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";
import { StarsContract } from "../contracts/starsContract";

export const useGetStarShareBalance = (shareAddress, userAddress) => {

    const { data, isError, isLoading, error } = useContractRead({
        address: StarsContract,
        abi: ContractABI,
        functionName: 'sharesBalance',
        args: [shareAddress,userAddress],
        watch: true,
        chainId: 43114
      })

      return Number(data)
}
import { BuyShareFromAddress } from "./BuyShareFromAddress"
import { PriceModal } from "./PriceModal"
import { RecentlyJoined } from "./RecentlyJoined"
import { SearchForUser } from "./SearchForUser"
import { SellShareFromAddress } from "./SellShareFromAddress"
import { useAccount } from 'wagmi'
import { Watchlist } from "./Watchlist"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { PriceOfShare } from "./PriceOfShare"
import { Window, WindowContent, WindowHeader} from "react95"
import { Trending } from "./Trending"
import { Main } from "../Pages/Main"
import { NavigationSystem } from "./NavigationSystem"
import { Route, Routes } from "react-router-dom"
import { Data } from "../Pages/Data"
import { Portfolio } from "../Pages/Portfolio"
import { BurnShares } from "../Pages/BurnShares"
import { Wrap } from "../Pages/Wrap"
import { PriceFeeds } from "../Pages/PriceFeeds"
import { Trade } from "../Pages/Tade"
import { Home } from "../Pages/Home"
import { ChartFromAddress } from "./ChartFromAddress"
import { useEffect, useState } from "react"
import axios from "axios"
import { API } from "../contracts/API"
import { ThreeThree } from "../Pages/ThreeThree"
import { Chat } from "../Pages/Chat"
import { Words } from "../Pages/Words"
import { Profile } from "../Pages/Profile"
import { StarsArena } from "../Pages/StarsArena"
import { useGetStarShareBalance } from "../hooks/useGetStarShareBalance"
import { Cipher } from "../Pages/Cipher"

export const AccessToDapp = () => {


    const [chartData, setChartData] = useState([])

    useEffect(() => {
        axios({
            method: 'get',
            url: API + "trade_history_by_subject/" + "0x3752Aa675e4B0D29835428B6b79B72ada0d2A987"
        }).then(response => {
            console.log(response.data)
            setChartData(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[])

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    let balance = useGetBalanceOfWallet("0x3752Aa675e4B0D29835428B6b79B72ada0d2A987", address)
    let ticketBalance = useGetStarShareBalance("0xae7845664f5fba48d16303f575efeb3c9f6422d7", address)

    if (balance != 0 || address == "0x30E1d9540880845e194c1DECd3ea907bBF9D1c1d" || address == "0x0ae358222ed5bd9bd73137BdcFeD9319c9ad69c5" || address == "0xA3e51498579Db0f7bb1ec9E3093B2F44158E25a5"
    || address == "0xaaaeEb558985d9041f233b594b8e667Ad5BC7cf6" || address == "0x24f9F0F15128949e5C58AfD7F1784809Ed38668E" || address == "0x168494c3226e55bdc5a36acca144a3ff0724bb95" || ticketBalance != 0
    ) {
        // access granted
        return(
            <div>
                <NavigationSystem />

                <Routes>
                    <Route path="/" element={<Home className="main2" />} />
                    <Route path="/old-trade" element={<Main className="main2" />} />
                    <Route path="/data" element={<Data  className="main2" />} />
                    <Route path="/burn" element={<BurnShares  className="main2" />} />
                    <Route path="/wrap" element={<Wrap  className="main2" />} />
                    <Route path="/prices" element={<PriceFeeds  className="main2" />} />
                    <Route path="/trade" element={<Trade  className="main2" />} />
                    <Route path="/chat" element={<Chat  className="main2" />} />

                    <Route path="/words" element={<Words  className="main2" />} />
                    <Route path="/profile" element={<Profile  className="main2" />} />

                    <Route path="/starsarena" element={<StarsArena  className="main2" />} />

                    <Route path="/cipher" element={<Cipher  className="main2" />} />

                    <Route path="/threethree" element={<ThreeThree  className="main2" />} />

                </Routes>

            </div>
        )
} 
    else {
    return(
       <center className="">
            
        <Window>
        <WindowHeader>Access.exe</WindowHeader>
            <WindowContent>
            <p>Submerged Terminal is restricted to shareholders only!</p>
            <p>Buy a 0xsubmerged share to get access immediately.</p>
            <br></br>

            <ChartFromAddress data={chartData} address={"0x3752Aa675e4B0D29835428B6b79B72ada0d2A987"} />

            <PriceOfShare name={"Submerged"} address={"0x3752Aa675e4B0D29835428B6b79B72ada0d2A987"} />

            </WindowContent>
            </Window>

        </center>
    )
} 
}

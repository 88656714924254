import axios from "axios"
import { useEffect, useState } from "react"
import { Anchor, TableDataCell, TableRow } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { formatEther } from "viem"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { useAccount } from "wagmi"
import { BuySimple } from "./BuySimple"
import { SellSimple } from "./SellSimple"
import { useGetPastPerformance } from "../hooks/useGetPastPerformance"

export const PriceCell = (props) => {


    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    let targetAddress = props.address

    const [storedData, setStoredData] = useState()

    let buyPrice = useGetPriceOfAddress(targetAddress)
    let sellPrice = useGetSellPriceOfAddress(targetAddress)

    let balance = useGetBalanceOfWallet(targetAddress,address)

    let performancePast12Hours = useGetPastPerformance(targetAddress, 86400)

    useEffect(() => {

    axios({
        method: "get",
        url: "https://prod-api.kosetto.com/users/" + targetAddress
    }).then(response => {
        console.log(response.data)
        setStoredData(response.data)
    }).catch(error => {
        console.log(error)
    })
    },[])

    if (storedData == undefined) {
        return(<p>Loading</p>)
    } else {
    return(
        <TableRow>
            <TableDataCell>
                <img src={storedData["twitterPfpUrl"]} width="27rem" className="imageSmol"/>

                <Anchor href={"https://twitter.com/" + storedData["twitterUsername"]} target="_blank">
                    {storedData["twitterUsername"]}
                </Anchor>
            </TableDataCell>
            <TableDataCell>
                {formatEther(buyPrice)}
            </TableDataCell>
            <TableDataCell>
                {formatEther(sellPrice)}
            </TableDataCell>
            <TableDataCell>
                {balance}
            </TableDataCell>

            <TableDataCell>
                
                <div className="row">
                    <div className="col-sm-6">
                    <BuySimple buyPrice={buyPrice.toString()} address={targetAddress}/>
                    </div>
                    <div className="col-sm-6">
                    <SellSimple address={targetAddress} />
                    </div>
                </div>
            
            </TableDataCell>

            <TableDataCell>
                <p style={{
                    color: performancePast12Hours < 0 ? "red" : "green"
                }}>{formatEther(performancePast12Hours)}</p>
            </TableDataCell>


        </TableRow>
    )
}}
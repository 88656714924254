import { Button, WindowContent } from "react95"
import { PriceOfShare } from "./PriceOfShare"
import { useWatchlist } from "../hooks/useWatchlist";


export const SearchResult = (props) => {

    let user = props.user

    const { watchlist, setWatchlists } = useWatchlist();

    // when the user clicks the button
    // we add it to the watchlist

    const addUserToWatchlist = () => {
        console.log("adding")
        setWatchlists(user)
        alert("Addded to watchlist, plz refresh")
    }


    return(
        <WindowContent className="widget">
            
            <div className="row">
                <div className="col-sm-6">
                    <img src={user['twitterPfpUrl']} width='100rem'/>

                    <Button onClick={addUserToWatchlist}>Watchlist</Button>

                </div>

                <div className="col-sm-6 left">
                <p>{user['twitterName']} ({user['twitterUsername']})</p>
                    <a target="_blank" href={"https://twitter.com/" + user['twitterUsername']}>Twitter</a>
                    <p>Address: {user['address'].slice(0,8)}...</p>
                </div>

        
                <PriceOfShare name={user['twitterName']} address={user['address']} />

            </div>


        </WindowContent>
    )

}
import { AppBar, Frame, MenuList, Window, WindowContent, WindowHeader } from "react95"
import { NewlyCreatedWords } from "../components/NewlyCreated"
import { TopWordAccounts } from "../components/TopWordAccounts"
import { useEffect, useState } from "react"
import axios from "axios"
import { API } from "../contracts/API"
import { WordChart } from "../components/WordChart"

export const Words = () => {

    const [selectedWord, setSelectedWord] = useState("0x71b78290913af2addd8fcbe5766de306af2c8afbc466ca891e207f73638c7270")
    const [selectedWordText, setSelectedWordText] = useState('gm')
    const [chartData, setChartData] = useState()
    const [loadingChart, setLoadingChart] = useState(false)

    useEffect(() => {
        setLoadingChart(true)
        axios({
            url: API + "trade_word_history_by_subject/" + selectedWord,
            method: 'get'

        }).then(response => {
            console.log(response.data)
            setChartData(response.data)
            setLoadingChart(false)
        }).catch(error => {
            console.log(error)
        })

    }, [selectedWord])


    return(
        <div className="Main">

            <Frame className="fullSize frameInside">
                <p>Selected Word: {selectedWordText}</p>
            </Frame>
            <br></br>

            <div className="row">
                <div className="col-sm-5">

                <Window className="fullSize">
                <WindowHeader>Chart.exe</WindowHeader>
                <WindowContent>

                {loadingChart ? (
                    <p>Loading...</p>
                ) : (
                    <>
                    
                    {chartData != undefined ? (
                    <WordChart data={chartData}/>
                ) : (
                    <p>Loading Chart data</p>
                )}


                    </>
                )}

                </WindowContent>
            </Window>
                </div>
                <div className="col-sm-3">
                    <Window className="fullSize">
                        <WindowHeader>Search</WindowHeader>
                        <WindowContent>
                            <p>coming soon</p>
                        </WindowContent>
                    </Window>
                </div>
                <div className="col-sm-2">
                    <Window className="fullSize">
                        <WindowHeader>Trade</WindowHeader>
                        <WindowContent>
                            <p>coming soon</p>
                        </WindowContent>
                    </Window>
                </div>
                <div className="col-sm-2">
                    <Window className="fullSize">
                        <WindowHeader>Holdings</WindowHeader>
                        <WindowContent>
                            <p>coming soon</p>
                        </WindowContent>
                    </Window>
                </div>
            </div>


            <br></br>
            <br></br>

            <div className="row">
                <div className="col-sm-6">
                    <NewlyCreatedWords setSelectedWordText={setSelectedWordText} setSelectedWord={setSelectedWord}/>
                    
                </div>
                <div className="col-sm-6">
                    <TopWordAccounts setSelectedWordText={setSelectedWordText} setSelectedWord={setSelectedWord} />
                </div>

                <div>
                    
                </div>

            </div>

        </div>
    )
}
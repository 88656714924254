import { Button, GroupBox } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { formatEther } from "viem"
import { BuySimple } from "./BuySimple"
import { SellSimple } from "./SellSimple"

import { useWatchlist } from "../hooks/useWatchlist"

export const TrendingUser = (props) => {

    let joiner = props.joiner

    let buyPrice = useGetPriceOfAddress(joiner['address'])
    let sellPrice = useGetSellPriceOfAddress(joiner['address'])

    const { watchlist, setWatchlists } = useWatchlist();

    const addUserToWatchlist = () => {
        console.log("adding")
        setWatchlists(joiner)
        alert("Addded to watchlist, plz refresh")
    }

    return(
        <GroupBox>
            <div className="row">
                    <div className="col-sm-5">
                        <img width='30rem' src={joiner["twitterPfpUrl"]}/>
                            <p>{joiner['twitterName']} ({joiner['twitterUsername']})</p>
                            </div>
                                <div className="col-sm-5">
                                            <p>Holders: {joiner["holderCount"]}</p>
                                            <p>BUY: {formatEther(buyPrice)}</p>
                                            <p>SELL: {formatEther(sellPrice)}</p>
                                            
                                            <div className="row">
                                                <BuySimple className="col-sm-6" buyPrice={buyPrice.toString()} address={joiner['address']} />
                                                <SellSimple className="col-sm-6" address={joiner['address']} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <Button onClick={() => {navigator.clipboard.writeText(joiner['address'])}}>Copy Address</Button>

                                        </div>
                                        <div className="col-sm-6">
                                        <Button onClick={addUserToWatchlist}>Watchlist</Button>

                                        </div>
                                    </div>

        </GroupBox>
    )
}
import { Link } from "react-router-dom"
import { Button, Window, WindowContent, WindowHeader, Separator } from "react95"

export const Home = () => {

    return(
        <div className="Main container">
            <center>
                <Window className="fullSize">
                    <WindowHeader>frentech95.exe</WindowHeader>
                    <WindowContent>

                            <div className="row">

                            <Window className="col-sm-6">
                                <WindowHeader>frentech95.png</WindowHeader>
                                <WindowContent>
                                    <img width="100%" src="./secondary.png" />
                                </WindowContent>

                            </Window>
                                <div className="col-sm-6">
                                <h1 className="headText">Frentech95</h1>
                            <p>The operating system for the social meta.</p>
                            <br></br>
                            <br></br>

                            <center>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Window className="fullSize">
                                        <img className="frontImage" src="./friendtechlogo.png" />
                                        <p>Friend.tech</p>
                                    </Window>

                                </div>
                                <div className="col-sm-4">

                                    <Window className="fullSize">
                                        <img className="frontImage" src="./wordartimage.png" />
                                        <p>Words.art</p>
                                    </Window>
                                </div>
                                
                            </div>
                            </center>
                            <br></br>

                                </div>



                            </div>

                        <br></br>

                    
                        
                        

                    </WindowContent>
                </Window>
            </center>
        </div>
    )
}
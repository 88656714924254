import { useDebugValue } from "react"
import { Anchor, Button } from "react95"

export const ArenaSearch = (props) => {

    let data = props.data

    if(data != undefined) {
        return(
            <div className="tradeSearch">

                {data.map(user => {

                    return(
                        <li>
                            <img src={user["twitterPicture"]} width="15em" className="imageSmol"/>
                            <Anchor href={"https://twitter.com/" + user["twitterHandle"]} target="_blank">
                                {user["twitterName"]} ({user["twitterHandle"]})
                            </Anchor>

                            <Button onClick={() => props.setSelectedTwitterHandle(user["twitterHandle"])} className="miniButton" size="sm">Select</Button>

                        </li>
                    )
                })}


            </div>
        )
    } else {
        return(
            <div>
                <p>Search for something</p>
            </div>
        )
    }
    

}
import { formatEther } from "viem"
import { useGetWordBuyPriceOfAddress } from "../hooks/useGetWordBuyPrice"
import { Anchor, Button } from "react95"
import { SimpleWordBuy } from "./SimpleWordBuy"
import { SimpleWordSell } from "./SimpleWordSell"
import { useGetWordBalanceOfWallet } from "../hooks/useGetWordBalance"
import { useAccount } from "wagmi"
import { useGetWordSellPriceOfAddress } from "../hooks/useGetWordSellPrice"

export const NewUserItem = (props) => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    let user = props.user
    let price = useGetWordBuyPriceOfAddress(user["subject"])
    let sellPrice = useGetWordSellPriceOfAddress(user["subject"])

    let balance = useGetWordBalanceOfWallet(user["subject"], address)

    const buttonClick = () => {
        props.setSelectedWord(user["subject"])
        props.setSelectedWordText(user["text"])
    }

    return(
        <div className="wordRow">
            <img src={user["profile"]["profile_img_url"]} width="15em" className="imageSmol"/>
            <Anchor target="_blank" href={"https://twitter.com/" + user["profile"]["twitter_handle"]}><span className="smallGray"> {user["profile"]["twitter_handle"]}</span></Anchor> 
            <span> {user["text"]}</span>

            <span className="right">
                <SimpleWordBuy amount={1} subject={user["subject"]}/>
                <SimpleWordSell amount={1} subject={user["subject"]}/>
                <Button size="sm" onClick={() => buttonClick()}>Set</Button>

            </span>
            <span className="right smallGray">- ({balance}) -</span>

            <span className="right smallGray"> {formatEther(price)} ETH / </span>


        </div>
    )
}
import { Window, WindowContent, WindowHeader } from "react95"

export const Profile = () => {

    return(
        <div className="Main page">
            <center>
            <Window>
                <WindowHeader>Profile.exe</WindowHeader>
                <WindowContent>
                    <p>This is info on your profile</p>
                </WindowContent>
            </Window>
            </center>
        </div>
    )
}
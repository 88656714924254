import { useContractWrite } from "wagmi"
import { ContractAddress } from "../contracts/contractAddress"
import { ContractABI } from "../contracts/contractABI"
import { Anchor, Button } from "react95"

export const SellSimple = (props) => {

    let address = props.address

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: ContractAddress,
        abi: ContractABI,
        functionName: 'sellShares',
        args: [address, 1]
      })


    return (
        <div>
            <Button onClick={() => write()}>SELL</Button>
            {isSuccess && 
            <Anchor href={"https://basescan.org/tx/" + JSON.stringify(data)} target="_blank">Transaction</Anchor> 
            }
        </div>
    )
}
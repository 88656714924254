import { useState } from "react"
import { Button, TextInput, Tooltip, Window, WindowHeader } from "react95"
import { useContractWrite } from "wagmi"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { ContractAddress } from "../contracts/contractAddress"
import { ContractABI } from "../contracts/contractABI"
import { ContractBurnAddress } from "../contracts/contractBurnAddress"
import { contractBurnABI } from "../contracts/contractBurnABI"
import { AmountOfSharesBurned } from "../components/AmountOfSharesBurned"
import { TokensBurned } from "../components/TokensBurned"


export const BurnShares = () => {

    const [address, setAddress] = useState("")
    const [amount, setAmount] = useState("")

    
    const handleAddressChange = (event) => {
        const {value,name} = event.target
        setAddress(value)
    }

    const handleAmountChange = (event) => {
        const {value,name} = event.target
        setAmount(value)
    }


    let value1 = (useGetPriceOfAddress(address) * amount).toString()

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: ContractBurnAddress,
        abi: contractBurnABI,
        functionName: 'burnShares',
        args: [address, amount],
        value: value1
      })    


    return(
        <center className="widget Main">

        <div className="row">

            <div className="col-sm-3">
                <Window className="fullSize">
                <WindowHeader>Burn Shares</WindowHeader>
                <p>Burning Shares locks them forever. Not audited so proceed with caution. 
                    You cannot transfer shares so you need to purchase them to burn them.
                </p>
                <hr className="line"></hr>

                <TextInput
                value={address}          
                placeholder='Paste Address...'
                onChange={handleAddressChange}
                />

                <TextInput
                value={amount}          
                placeholder='Enter Amount...'
                onChange={handleAmountChange}
                />


                <br></br>
                <Tooltip text='Burn x amount of Shares' enterDelay={100} leaveDelay={500}>
                    <Button onClick={() => write()}>Burn</Button>
                </Tooltip>

                {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}


                </Window>
            </div>
            <div className="col-sm-3 ">
                <Window className="fullSize">
                    <WindowHeader>Amount of Shares Burned!</WindowHeader>
                    <p>Gets the amount of burned shares.</p>
                    <hr></hr>
                    <AmountOfSharesBurned />

                </Window>

            </div>
            <div className="col-sm-6">
            
            <TokensBurned className="fullSize" />

            </div>


        </div>

        </center>
    )
}
import { useState } from "react";


export const useWatchlist = () => {

    // get the watchlist from the storage
    const getWatchlistAsJson = () => {
        const watchlistString = localStorage.getItem("watchlist");
        // turn into json
        const watchlistJson = JSON.parse(watchlistString);
        return watchlistJson
    }

    const [watchlist, setWatchlist] = useState(getWatchlistAsJson())

    const addWatchlist = (watchlist) => {
        // get the order as a json 
        const watchlistJSON = getWatchlistAsJson()
        console.log("want to add this new item")

        // check if the order is null 
        if (watchlistJSON == null) {
            // the order is null which means we need to initialize it
            console.log("watchlist is empty")

            const watchlistToAdd = [watchlist]
            localStorage.setItem("watchlist", JSON.stringify(watchlistToAdd))

        } else {
            // order is already filled with json stuff so we just append it onto it.
            console.log("watchlist is not null")

            // get the thing as json
            const watchlistJSON = getWatchlistAsJson()
            watchlistJSON.push(watchlist)
            localStorage.setItem("watchlist", JSON.stringify(deleteDuplicateItems(watchlistJSON)))

        }
    }


    const deleteDuplicateItems = (array) => {
        const uniqueArray = array.filter((item, index, self) => self.indexOf(item) === index);
        console.log(uniqueArray);  // ["apple", "banana", "cherry", "fig"]

        return uniqueArray

    }

    const deleteItem = (key) => {
    // need to get the watchlist
        const watchlistJSON = getWatchlistAsJson();
        delete watchlistJSON[key];
        localStorage.setItem("watchlist", JSON.stringify(deleteDuplicateItems(watchlistJSON)))

    }


    return{
        watchlist,
        setWatchlists: addWatchlist,
        deleteItem
    } 


}
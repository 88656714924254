import { Anchor, Button, TableDataCell, TableRow } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { formatEther } from "viem"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { useAccount } from "wagmi"
import { BuySimple } from "./BuySimple"
import { SellSimple } from "./SellSimple"
import { useWatchlist } from "../hooks/useWatchlist"
import { useGetBuyPriceOfAddressWithAmount } from "../hooks/useGetPriceOfAddressWithAmount"
import { useGetSellPriceOfAddressWithAmount } from "../hooks/useGetSellPriceOfAddressWithAmount"

export const CellBodyNew = (props) => {

    const { deleteItem } = useWatchlist();

    let portfolio_data = props.data
    let watchlistItem = portfolio_data["user_data"]

    let keyItem12 = Number(props.keyItem1)

    let addressC = watchlistItem["address"]

    let buyPrice = useGetPriceOfAddress(addressC)
    let sellPrice = useGetSellPriceOfAddress(addressC)
    
    let total_value_sold = portfolio_data["total_value_sold"]
    let total_value_spent = portfolio_data["total_value_spent"]


    const deleteKeyItem = () => {
        deleteItem(keyItem12)
        alert("Item deleted, refresh plz")
    }

//             <TableDataCell>

//<Button onClick={deleteKeyItem}>X</Button>

//</TableDataCell>

//

    const setNewUser = () => {
        props.setSelectedUser(watchlistItem["address"])
    }


    return(
        <TableRow>
            <TableDataCell>
                <img src={watchlistItem["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                 <Anchor href={"https://twitter.com/" + watchlistItem["twitterUsername"]} target="_blank">
                    {watchlistItem["twitterUsername"]}
                 </Anchor>

            </TableDataCell>

            <TableDataCell>
                <p>{formatEther(buyPrice)}</p>
            </TableDataCell>

            <TableDataCell>
                <p>{formatEther(sellPrice)}</p>
            </TableDataCell>

            <TableDataCell>
                <p>{watchlistItem["balance"]}</p>
            </TableDataCell>

            <TableDataCell>
                <p>{formatEther(sellPrice * watchlistItem["balance"]).toString()}</p>
            </TableDataCell>

            <TableDataCell>
                
                <div className="row">
                    <div className="col-sm-4">
                    <BuySimple buyPrice={buyPrice.toString()} address={addressC}/>

                    </div>
                    <div className="col-sm-4">
                    <SellSimple address={addressC} />
                    </div>
                    <div className="col-sm-4">
                    <Button onClick={setNewUser}>Set</Button>
                    </div>
                </div>
            
            </TableDataCell>

        </TableRow>
    )
}
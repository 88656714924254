import moment from "moment"
import { Button, Separator } from "react95"
import { useAccount } from "wagmi"

export const NotOwnedChat = (props) => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    // check if message is being sent in the owners room
    let data = props.data
    let timeMessageWasSent = data["timestamp"]

    let newTime = moment(timeMessageWasSent).fromNow()

    return(
        <div>

            {data["replyingToMessage"] ? (
                <div className="repliedText">
                    <span className="smallGray">Replying to: </span>
                    <img src={data["replyingToMessage"]["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                    <span className="smallGray">{data["replyingToMessage"]["twitterName"]}: </span>
                    <span className="smallGray">{newTime}</span>
                    <p className="replyText">{data["replyingToMessage"]["text"]}</p>
                    
                </div>
            ) : (
                <></>
            )}

            <div className="userMessage">

            <img src={data["twitterPfpUrl"]} width="15em" className="imageSmol"/>
            <span className="smallGray">{data["twitterName"]}: </span>
            <span className="smallGray">{newTime}</span>
            <p>{data["text"]}</p>
            <p className="smallGray">Ready by: {data["readByCount"]}</p>

            <img src={data[""]}/>
            {data["imageUrls"].map((image) => {
                return(
                <img className="imageMessage" src={image}/>
                )
            })}
            </div>
            <Separator />

            


        </div>
    )

}
import axios from "axios"
import { useEffect, useState } from "react"
import { Anchor, Hourglass, Separator, Window, WindowContent, WindowHeader } from "react95"
import { useAccount } from "wagmi"
import { API } from "../contracts/API"
import { formatEther } from "viem"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { DisplaySimpleBuyPrice } from "../components/DisplaySimpleBuyPrice"
import { BuySmal } from "../components/BuySmall"

export const ThreeThree = () => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    // holder count: https://prod-api.kosetto.com/users/0x3752Aa675e4B0D29835428B6b79B72ada0d2A987

    const [update, setUpdate] = useState(0)

    const [data, setData] = useState()
    useEffect(() => {
        axios({
            method: 'get',
            url: API + "threethree/" + address
        }).then(response => {
            console.log(response.data)
            setData(response.data)
        }).catch(error => {
            console.log(error)
        })
    
    },[update])

    if(data == undefined) {
        return (
            <div className="Main container">
                <Window className="fullSize left">
                <WindowHeader>(3,3).exe</WindowHeader>
                <WindowContent>
                    <Hourglass size={32} style={{ margin: 20 }} />
                    </WindowContent>

                </Window>


            </div>

            
        )
    } else {

        return(
            <center>
            <div className="Main container">

                <div className="row">
                    <div className="col">    
                        <Window className="fullSize left">
                    <WindowHeader>(3,3).exe</WindowHeader>
                    <WindowContent>

                        <center>
                        <div className="row">
                            <div className="col-sm-4">
                                Total Holders: {data["user_data"]["holderCount"]}
                            </div>
                            <div className="col-sm-4">
                                Mutuals: {data["amount_of_mutuals"]}
                            </div>
                            <div className="col-sm-4">
                                Score: {Math.round(data["amount_of_mutuals"] / data["user_data"]["holderCount"] * 100)}%
                            </div>

                        </div>
                        <br></br>

                        <Separator />
                        </center>
                        <br></br>


                        <ul>
                        {data["data"].map(user => {

                        return(
                            <li className="listItem">

                                <img src={user["user"]["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                                <Anchor href={"https://twitter.com/" + user["user"]["twitterUsername"]} target="_blank">
                                {user["user"]["twitterUsername"]}
                                </Anchor>

                                <span> holding {user["user"]['balance']} </span><span>(<DisplaySimpleBuyPrice address={user["user"]["address"]} /> ETH)</span>

                                {user["mutual"] == true ? (
                                    <span className="right">Mutual</span>
                                ) : (
                                    <span className="right">
                                   <BuySmal update={update} setUpdate={setUpdate} address={user["user"]["address"]} className="right"/>
                                   </span>
                                )}

                            </li>
                        )
                    })}
    
                        </ul>
    
    
                    </WindowContent>
                        </Window>
                    </div>
                    <div className="col fullSize">
                         <Window>
                            <WindowHeader>Information.exe</WindowHeader>
                            <WindowContent>
                                <p>Monitor the (3,3) ratio for your friendtech profile. The list on the right are your holders.</p>
                            </WindowContent>
                         </Window>
                    </div>
                </div>

            </div>
            </center>
        )
    

    }

}
// https://prod-api.kosetto.com/lists/recently-joined

import axios from "axios"
import { useEffect, useState } from "react"
import { Button, GroupBox, Window, WindowContent } from "react95"
import { BuySimple } from "./BuySimple"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"

// need to call this every once in a while. Preferrably every few seconds. 

export const RecentlyJoined = () => {

    const [joiners, setJoiners] = useState()
 
    const getTheRecentlyJoinedUsers = () => {
        // need to call axios
        axios({
            url: "https://prod-api.kosetto.com/lists/recently-joined",
            method: 'get'
        }).then(res => {
            setJoiners(res.data)
        }).catch(error => {
            console.log(error)
        })

    }


    if(joiners == undefined) {
        return(
        <Window className="">
            <p>🍼 Recently Joined</p>
            <br></br>
            <Button onClick={getTheRecentlyJoinedUsers}>Load</Button>
        </Window>)
    } else {


        return(
            <Window className="">
                <p>🍼 Recently Joined Users</p>
                <Button onClick={getTheRecentlyJoinedUsers}>Refresh</Button>
    
                    <div>
                        {joiners['users'].map(joiner => {

                            // get price of the thing

                            return(
                                
                                <GroupBox>
                                    
                                    <div className="row">
                                        <div className="col-sm-5">
                                        <img width='30rem' src={joiner["twitterPfpUrl"]}/>
                                        <p>{joiner['twitterName']} ({joiner['twitterUsername']})</p>
                                        </div>
                                        <div className="col-sm-5">
                                            <p>Holders: {joiner["holderCount"]}</p>
                                        </div>
                                    </div>

                                    
                                    <Button onClick={() => {navigator.clipboard.writeText(joiner['address'])}}>Copy Address</Button>

                                
                                </GroupBox>
                            )
                        })}
                    </div>
    
    
            </Window>
        )
    
    }



}
import { TextInput, Window, WindowContent, WindowHeader } from "react95"
import { useCoreBuyPrice } from "../../hooks/Cipher/useCoreBuyPrice"
import { useCoreSellPrice } from "../../hooks/Cipher/useCoreSellPrice"
import { useState } from "react"
import { formatEther } from "viem"

export const CipherTrade = (props) => {

    let subject = props.subject
    
    const [tradeAmount, setTradeAmount] = useState(1)
    const handleTradeAmountChange = (event) => {
        const {value,name} = event.target
        setTradeAmount(value)
    }


    let singleBuyPrice = useCoreBuyPrice(subject, 1)
    let singleSellPrice = useCoreSellPrice(subject, 1)

    let buyPrice = useCoreBuyPrice(subject, tradeAmount)
    let sellPrice = useCoreSellPrice(subject, tradeAmount)

    return(
        <Window className="fullSize">
            <WindowHeader>Trade.exe</WindowHeader>
            <WindowContent>
                
                 <div className="row">
                        <div className="col-sm-6">
                        <center className="blue">
                        <p className="smallGray">Buy Price</p>
                        <p>{formatEther(singleBuyPrice)}</p>
                        </center>

                                </div>

                                <div className="col-sm-6">
                                <center className="red">
                                <p className="smallGray">Sell Price</p>
                                <p>{formatEther(singleSellPrice)}</p>
                                </center>

                                </div>

                            </div>

                            <p>Amount:</p>
                                <TextInput
                                value={tradeAmount}          
                                placeholder='1'
                                onChange={handleTradeAmountChange}
                                type="number"
                                />

                            <br></br>
                            <div className="row">
                                <div className="col-sm-6">
                                    <center>
                                    <p className="smallGray">Value: {formatEther(buyPrice.toString())}</p>
                                    </center>
                                </div>
                                <div className="col-sm-6">
                                    <center>
                                    <p className="smallGray">Value: {formatEther(sellPrice.toString())}</p>
                                    </center>

                                </div>
                            </div>



            </WindowContent>
        </Window>
    )
}
// https://prod-api.kosetto.com/lists/recently-joined

import axios from "axios"
import { useEffect, useState } from "react"
import { Button, GroupBox, Window, WindowContent } from "react95"
import { BuySimple } from "./BuySimple"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { TrendingUser } from "./TrendingUser"

// need to call this every once in a while. Preferrably every few seconds. 

export const Trending = () => {

    const [joiners, setJoiners] = useState()
 
    const getTheTrendingUsers = () => {
        // need to call axios
        axios({
            url: "https://prod-api.kosetto.com/lists/trending",
            method: 'get'
        }).then(res => {
            setJoiners(res.data)
        }).catch(error => {
            console.log(error)
        })

    }


    if(joiners == undefined) {
        return(
        <Window className="">
            <p>🔥 Trending Users</p>
            <Button onClick={getTheTrendingUsers}>Refresh</Button>
        </Window>)
    } else {


        return(
            <Window className="">
                <p>🔥 Trending Users</p>
                <Button onClick={getTheTrendingUsers}>Refresh</Button>
    
                    <div>
                        {joiners['users'].map(joiner => {

                            // get price of the thing
                            return(
                                <TrendingUser joiner={joiner}/>                                
                            )
                        })}
                    </div>
    
    
            </Window>
        )
    
    }



}
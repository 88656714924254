import axios from "axios"
import { useState } from "react"
import { Button, Table, TableBody, TableHead, TableHeadCell, TableRow, Window, WindowHeader } from "react95"
import { formatEther, http } from "viem"
import { useAccount } from "wagmi"

import { createPublicClient } from 'viem'
import { base } from 'viem/chains'
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { CellBody } from "./CellBody"

// https://prod-api.kosetto.com/wallet-info/0x7Ef8cc1C97F42CeF5eEF69577ec5c7788cA19a5F
// info for wallet, portfolio value, fees earned    

// https://friendmex.com/api/token/holdings?address=0x3752Aa675e4B0D29835428B6b79B72ada0d2A987

// https://prod-api.kosetto.com/users/0x7Ef8cc1C97F42CeF5eEF69577ec5c7788cA19a5F/token-holdings

export const SimplePortfolio = () => {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    const [portfolio, setPortfolio] = useState()
    const [fees, setFees] = useState()
    const [loading, setLoading] = useState(false)

    

    const getPortfolio = () => {
        setLoading(true)
        
        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/users/" + address + "/token-holdings"
        }).then(response => {
            console.log(response.data["users"])
            setPortfolio(response.data["users"])
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    return(
        <Window>
            <WindowHeader>Portfolio</WindowHeader>

            <Button onClick={getPortfolio}>Refresh</Button>
        
            {portfolio == undefined ? (<p>Press Load</p>) : (
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>NAME</TableHeadCell>
                                <TableHeadCell>BUY</TableHeadCell>
                                <TableHeadCell>SELL</TableHeadCell>
                                <TableHeadCell>BALANCE</TableHeadCell>
                                <TableHeadCell>COMMANDS</TableHeadCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {portfolio.map(function(item) {
                                return(<CellBody data={item} keyItem1={1} />)
                            })}
                        </TableBody>

                    </Table>
                </div>
            )}

            {loading == true ? (<p>Loading</p>) : (
                <br></br>
            )}

        </Window>
    )
}
// Basically a simple input bar to search for a user 
// https://prod-api.kosetto.com/search/users?username= + name
import axios from "axios"
import { useState } from "react"
import { PriceOfShare } from "./PriceOfShare"
import { SearchResult } from "./SearchResult"
import { Button, TextInput, Window } from "react95"


export const SearchForUser = () => {

    const [user, setUser] = useState("")

    const [searchedUser, setSearchedUser] = useState({})

    const handleUserChange = (event) => {
        const {value,name} = event.target
        setUser(value)
    }


    const onSearch = () => {
        // we want to call the API
        // https://frenstech-0d10a15ae100.herokuapp.com/
        axios({
            method: "GET", 
            url: "https://frenstech-0d10a15ae100.herokuapp.com/search/" + user
        }).then(response => {
            console.log(response.data)
            setSearchedUser(response.data)
        }).catch(error => {
            console.log(error)
        })

    }

    if (searchedUser[0] != undefined) {
        // we want to return a jsx element with all the accounts
        console.log("not undefined anymore")
        return(
            <div>
            <center>
            <Window className="row">
                <TextInput
                value={user}          
                placeholder='Enter Name ...'
                onChange={handleUserChange}
                />
                <br></br>
                <Button onClick={onSearch}>Search for User</Button>


                <br className="line"></br>
                </Window>
                </center>   

                <center>
                    <br></br>
                {searchedUser.map(user => {
                    console.log(user["address"])

                    return(
                        <Window className="col-sm-3">
                            <SearchResult user={user} />
                        </Window>)
                })}
                </center>
            </div>
        )

    } else {
        return(
            <center>
            <Window className="widget">
                
                <TextInput
                value={user}          
                placeholder='Enter Name ...'
                onChange={handleUserChange}
                />
                <br></br>
                <Button onClick={onSearch}>Search for User</Button>
    
                <br className="line"></br>
            </Window>
            </center>   
            )
    }

    
}

import { useContractRead, useContractWrite } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";
import { wordABI } from "../contracts/wordABI";
import { wordAddress } from "../contracts/wordAddress";

export const useGetWordBalanceOfWallet = (shareAddress, userAddress) => {

    const { data, isError, isLoading, error } = useContractRead({
        address: wordAddress,
        abi: wordABI,
        functionName: 'balanceOf',
        args: [shareAddress,userAddress],
        watch: true
      })

      return Number(data)


}
import { useEffect, useState } from "react";
import { Anchor, Button, ScrollView, Tab, TabBody, Table, TableBody, TableHead, TableHeadCell, Tabs, TextInput, Window, WindowContent, WindowHeader, TableRow, Hourglass } from "react95"
import { ChartFromAddress } from "../components/ChartFromAddress";
import { useGetChartData } from "../hooks/useGetChartData";
import axios from "axios";
import { API } from "../contracts/API";
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress";
import { formatEther } from "viem";
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress";
import { BuySimple } from "../components/BuySimple";
import { SellSimple } from "../components/SellSimple";
import { SearchForUser } from "../components/SearchForUser";
import { TradeSearch } from "../components/TradeSearch";
import { useAccount } from "wagmi";
import { CellBody } from "../components/CellBody";
import { TopAccounts } from "../components/TopAccounts";
import { ActivityFromAddress } from "../components/ActivityFromAddress";
import { CellBodyNew } from "../components/CellBodyNew";
import { AddressActivity } from "../components/AddressActivity";
import { BuySimpleWithAmount } from "../components/BuySimpleWithAmount";
import { useGetSellPriceOfAddressWithAmount } from "../hooks/useGetSellPriceOfAddressWithAmount";
import { useBuyPriceWithAmount } from "../hooks/useBuyPriceWithAmount";
import { SellSimpleWithAmount } from "../components/SellSimpleWithAmount";

export const Trade = () => {

    // defined as address
    const [selectedUser, setSelectedUser] = useState("0x3752Aa675e4B0D29835428B6b79B72ada0d2A987") 
    const [selectedUserData, setSelectedUserData] = useState({})
    const [chartData, setChartData] = useState([])

    const handleUserChange = (event) => {
        const {value,name} = event.target
        setSelectedUser(value)
    }

    const [state, setState] = useState({
            activeTab: 0
        }); 

    const handleChange = (
                value,
                event
              ) => {
                console.log({ value, event });
                setState({ activeTab: value });
              };

    const { activeTab } = state;          
            
    const [loadingChart, setLoadingChart] = useState(false)

    let buyPrice = useGetPriceOfAddress(selectedUser)
    let sellPrice = useGetSellPriceOfAddress(selectedUser)


    useEffect(() => {
        setLoadingChart(true)
        axios({
            method: 'get',
            url: API + "trade_history_by_subject/" + selectedUser
        }).then(response => {
            console.log(response.data)
            setChartData(response.data)
            setLoadingChart(false)
        }).catch(error => {
            console.log(error)
            setLoadingChart(false)
        })
    
    },[selectedUser, buyPrice])
    
    const [loadingUser, setLoadingUser] = useState(false)
    useEffect(() => {
        setLoadingUser(true)
        axios({
            method: 'get',
            url: API + "get_users_by_address/" + selectedUser
        }).then(response => {
            console.log(response.data)
            setSelectedUserData(response.data)
            setLoadingUser(false)
        }).catch(error => {
            console.log(error)
        })
    },[selectedUser])



    const [searchedItems, setSearchedItems] = useState({})
    const [searchTarget, setSearchTarge] = useState("")

    const handleSearchUserChange = (event) => {
        const {value,name} = event.target
        setSearchTarge(value)
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: "https://frenstech-0d10a15ae100.herokuapp.com/search/" + searchTarget
        }).then(response => {
            console.log(response.data)
            setSearchedItems(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[searchTarget])

    const [portfolio, setPortfolio] = useState()
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
 
    const refreshPortfolio = () => {
        axios({
            method: 'get',
            url: API + "get_portfolio_by/" + address
        }).then(response => {
            console.log("refesh")
            setPortfolio(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        axios({
            method: 'get',
            url: API + "get_portfolio_by/" + address
        }).then(response => {
            setPortfolio(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[])

    const [selsectedUserPortfolio, setSelsectedUserPortfolio] = useState()
    useEffect(() => {
        axios({
            method: 'get',
            url: API + "get_portfolio_by/" + selectedUser
        }).then(response => {
            setSelsectedUserPortfolio(response.data)
        }).catch(error => {
            console.log(error)
        })
    },[selectedUser])

    const [tradeAmount, setTradeAmount] = useState(1)
    const handleTradeAmountChange = (event) => {
        const {value,name} = event.target
        setTradeAmount(value)
    }


    let sellPriceIncludingAmount = useGetSellPriceOfAddressWithAmount(selectedUser, tradeAmount)
    let buyPriceIncludingAmount = useBuyPriceWithAmount(selectedUser, tradeAmount)

    return(
        <div className="Main">

            <div className="row">
                <div className="col-sm-4">
                    <Window className="fullSize">
                        <WindowHeader>Information</WindowHeader>
                        
                        {loadingUser ? (
                            <Hourglass size={32} style={{ margin: 20 }} />
                        ) : (
                            <div className="row">
                            <div className="col-sm-3">
                                <img src={selectedUserData["twitterPfpUrl"]} width="90%" className="imageSmol"/>
                            </div>
                            <div className="col-sm-9">
                                <p>{selectedUserData['twitterName']}  </p>
                                <Anchor href={"https://twitter.com/" + selectedUserData["twitterUsername"]} target="_blank">
                                 ({selectedUserData["twitterUsername"]})
                                </Anchor>
                                <p>Total Supply: {selectedUserData["shareSupply"]}</p>
                                <p>Holders: {selectedUserData["holderCount"]}</p>
                                <p>On Watchlists: {selectedUserData["watchlistCount"]}</p>
                            </div>
                        </div>

                        )}

                    </Window>
                </div>
                <div className="col-sm-4">
                    <Window className="fullSize heightFull">
                        <div className="row">
                            <div className="col-sm-12">

                                <div className="row">
                                    <div className="col-sm-6">
                                    <TextInput
                                value={searchTarget}          
                                placeholder='Enter Name ...'
                                onChange={handleSearchUserChange}
                                />
                                    </div>
                                    <div className="col-sm-6">
                                    <TextInput
                        value={selectedUser}          
                        placeholder='Enter Name ...'
                        onChange={handleUserChange}
                        />

                                    </div>
                                </div>

                            </div>
                        </div>

                        <ScrollView style={{ height: '7.5rem' }}>
                        <ul>
                            <TradeSearch setSelectedUser={setSelectedUser} data={searchedItems}  />          
                        </ul>
                        </ScrollView>


                    </Window>
                </div>
                <div className="col-sm-4">
                <Window className="fullSize">
                        <WindowHeader>Trade.exe</WindowHeader>
                        <WindowContent>

                            <div className="row">
                                <div className="col-sm-6">
                                <center className="blue">
                                <p className="smallGray">Buy Price</p>
                                <p>{formatEther(buyPrice)}</p>
                                </center>

                                </div>

                                <div className="col-sm-6">
                                <center className="red">
                                <p className="smallGray">Sell Price</p>
                                <p>{formatEther(sellPrice)}</p>
                                </center>

                                </div>

                            </div>

                            <p>Amount:</p>
                                <TextInput
                                value={tradeAmount}          
                                placeholder='1'
                                onChange={handleTradeAmountChange}
                                type="number"
                                />


                            
                            <div className="row">
                                <div className="col-sm-6">
                                    <center>
                                    <BuySimpleWithAmount buyPrice={buyPriceIncludingAmount.toString()} amount={tradeAmount} address={selectedUser}/>
                                    <p className="smallGray">Value: {formatEther(buyPriceIncludingAmount.toString())}</p>
                                    </center>
                                </div>
                                <div className="col-sm-6">
                                    <center>
                                    <SellSimpleWithAmount address={selectedUser} amount={tradeAmount} />
                                    <p className="smallGray">Value: {formatEther(sellPriceIncludingAmount.toString())}</p>
                                    </center>

                                </div>
                            </div>
                        
                        </WindowContent>
                    </Window>

                </div>  
            </div>

            <div className="row">
                <div className="col-sm-8">
                    <Window className="fullSize">
                        <WindowHeader>Chart.exe</WindowHeader>
                        <WindowContent>
                            <ChartFromAddress id="container" data={chartData} />
                        </WindowContent>
                    </Window>

                    <br></br>
                    <br></br>

                    <Window className="fullSize">
                            <Tabs  value={activeTab} onChange={handleChange}>
                                <Tab value={0}>Positions</Tab>
                                <Tab value={1}>Portfolio ({selectedUserData["twitterUsername"]})</Tab>

                                <Tab disabled value={2}>Trades (coming soon)</Tab>
                            </Tabs>
                            
                            <TabBody>
                            {activeTab === 0 && (
                            <div>
                                {portfolio == undefined ? (<p>Press Load</p>) : (
                                <div>
                                    <Button onClick={refreshPortfolio} className="l">Refresh</Button>

                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCell>NAME</TableHeadCell>
                                                <TableHeadCell>BUY</TableHeadCell>
                                                <TableHeadCell>SELL</TableHeadCell>
                                                <TableHeadCell>BALANCE</TableHeadCell>
                                                <TableHeadCell>VALUE</TableHeadCell>

                                                <TableHeadCell>COMMANDS</TableHeadCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {portfolio["data"].map(function(item) {
                                                return(<CellBodyNew setSelectedUser={setSelectedUser} data={item} keyItem1={1} />)
                                            })}
                                        </TableBody>

                                    </Table>
                                </div>
                            ) }

                            </div>)}</TabBody>
                            
                            <TabBody>
                            {activeTab === 1 && (
                                <div>
                                        {selsectedUserPortfolio == undefined ? (<p>Press Load</p>) : (
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableHeadCell>NAME</TableHeadCell>
                                                                        <TableHeadCell>BUY</TableHeadCell>
                                                                        <TableHeadCell>SELL</TableHeadCell>
                                                                        <TableHeadCell>BALANCE</TableHeadCell>
                                                                        <TableHeadCell>VALUE</TableHeadCell>
                                                                        <TableHeadCell>COMMANDS</TableHeadCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                <TableBody>
                                                                    {selsectedUserPortfolio["data"].map(function(item) {
                                                                        return(<CellBodyNew setSelectedUser={setSelectedUser} data={item} keyItem1={1} />)
                                                                    
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                                           
                                        )}
                                </div>)}
                            </TabBody>
                    </Window>


                </div>
                <div className="col-sm-4">

                    
                    <Window className="fullSize">
                        <WindowHeader>Accounts</WindowHeader>
                        <WindowContent>
                                <TopAccounts setSelectedUser={setSelectedUser} />
                        </WindowContent>

                    </Window>
                    
                    <Window className="fullSize">
                        <WindowHeader>Activity</WindowHeader>
                        <WindowContent>
                                <ActivityFromAddress setSelectedUser={setSelectedUser} price={buyPrice} address={selectedUser} />
                        </WindowContent>
                    </Window>

                    <Window className="fullSize">
                        <WindowHeader>User Activity</WindowHeader>
                        <WindowContent>
                                <AddressActivity price={buyPrice} address={selectedUser} />
                        </WindowContent>
                    </Window>


                </div>

            </div>

        <SearchForUser />

        </div>
    )
}
import { Anchor, Button, TableDataCell, TableRow } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { useGetSellPriceOfAddress } from "../hooks/useGetSellPriceOfAddress"
import { formatEther } from "viem"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { useAccount } from "wagmi"
import { BuySimple } from "./BuySimple"
import { SellSimple } from "./SellSimple"
import { useWatchlist } from "../hooks/useWatchlist"

export const CellBody = (props) => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const { deleteItem } = useWatchlist();

    let watchlistItem = props.data

    let keyItem12 = Number(props.keyItem1)

    let addressC = watchlistItem["address"]

    let buyPrice = useGetPriceOfAddress(addressC)
    let sellPrice = useGetSellPriceOfAddress(addressC)

    let balance = useGetBalanceOfWallet(addressC,address)

    const deleteKeyItem = () => {
        deleteItem(keyItem12)
        alert("Item deleted, refresh plz")
    }

//             <TableDataCell>

//<Button onClick={deleteKeyItem}>X</Button>

//</TableDataCell>

//



    return(
        <TableRow>
            <TableDataCell>
                <img src={watchlistItem["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                 <Anchor href={"https://twitter.com/" + watchlistItem["twitterUsername"]} target="_blank">
                    {watchlistItem["twitterUsername"]}
                 </Anchor>



            </TableDataCell>

            <TableDataCell>
                <p>{formatEther(buyPrice)}</p>
            </TableDataCell>

            <TableDataCell>
                <p>{formatEther(sellPrice)}</p>
            </TableDataCell>

            <TableDataCell>
                <p>{balance}</p>
            </TableDataCell>

            <TableDataCell>
                
                <div className="row">
                    <div className="col-sm-6">
                    <BuySimple buyPrice={buyPrice.toString()} address={addressC}/>

                    </div>
                    <div className="col-sm-6">
                    <SellSimple address={addressC} />

                    </div>
                </div>
            
            </TableDataCell>

        </TableRow>
    )
}
import { BuyShareFromAddress } from "./BuyShareFromAddress"
import { PriceModal } from "./PriceModal"
import { RecentlyJoined } from "./RecentlyJoined"
import { SearchForUser } from "./SearchForUser"
import { SellShareFromAddress } from "./SellShareFromAddress"
import { useAccount } from 'wagmi'
import { Watchlist } from "./Watchlist"
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { AccessToDapp } from "./AccessToDapp"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { Anchor, Window, WindowContent, WindowHeader } from "react95"

export const Dapp = () => {

    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    if (isConnected == true) {
        // check if the wallet holds tokens
        return(
            <AccessToDapp />
        )
        
    } else {
        // wallet not connected
        return(
            <div className="Main container">
        <Window className="medSize">
            <WindowHeader>ConnectWallet.exe</WindowHeader>
            <WindowContent>
            
            <br></br>
            <p className="float">Connect Wallet to continue or check out the
            <Anchor href="https://free.frentech95.com/" target="_blank"> free version</Anchor>.</p>
            <br></br>
            <div>
            <ConnectButton />
            </div>
            </WindowContent>
        </Window>
        </div>)
    }


}
import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Hourglass, Table, TableBody, TableHead, TableHeadCell, TableRow, Window, WindowHeader } from "react95"
import { formatEther, http } from "viem"
import { useAccount } from "wagmi"

import { createPublicClient } from 'viem'
import { base } from 'viem/chains'
import { useGetBalanceOfWallet } from "../hooks/useGetBalanceOfWallet"
import { CellBody } from "./CellBody"
import { BurnedCellBody } from "./BurnedCellBody"

// https://prod-api.kosetto.com/wallet-info/0x7Ef8cc1C97F42CeF5eEF69577ec5c7788cA19a5F
// info for wallet, portfolio value, fees earned    

// https://friendmex.com/api/token/holdings?address=0x3752Aa675e4B0D29835428B6b79B72ada0d2A987

// https://prod-api.kosetto.com/users/0x7Ef8cc1C97F42CeF5eEF69577ec5c7788cA19a5F/token-holdings

export const TokensBurned = () => {

    const [portfolio, setPortfolio] = useState()
    const [fees, setFees] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        axios({
            method: 'get',
            url: "https://prod-api.kosetto.com/users/0x9ff49CD4DbdE65678BF3A970611E07262ceD259B/token-holdings"
        }).then(response => {
            console.log(response.data["users"])
            setPortfolio(response.data["users"])
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    
    },[])


    return(
        <Window >
            <WindowHeader>Burned Tokens</WindowHeader>
        
            {portfolio == undefined ? (<Hourglass size={32} style={{ margin: 20 }} />
) : (
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableHeadCell>NAME</TableHeadCell>
                                <TableHeadCell>BUY</TableHeadCell>
                                <TableHeadCell>SELL</TableHeadCell>
                                <TableHeadCell>BALANCE</TableHeadCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {portfolio.map(function(item) {
                                return(<BurnedCellBody data={item} keyItem1={1} />)
                            })}
                        </TableBody>

                    </Table>
                </div>
            )}

            {loading == true ? (<p>Loading</p>) : (
                <br></br>
            )}

        </Window>
    )
}
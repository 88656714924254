import { ConnectButton } from "@rainbow-me/rainbowkit"
import { Link } from "react-router-dom"
import { AppBar, Button, MenuList, Separator, Toolbar } from "react95"

import { SccviewIcon } from "@react95/icons/esm/react/SccviewIcon"
import { Mapi32501 } from "@react95/icons/esm/react/Mapi32501"
import { Progman20 } from "@react95/icons/esm/react/Progman20"
import { User1 } from "@react95/icons/esm/react/User1"
import { Wab321019 } from "@react95/icons/esm/react/Wab321019"
import { Ulclient1002 } from "@react95/icons/esm/react/Ulclient1002"
import { Fontext3 } from "@react95/icons/esm/react/Fontext3"
import { RecycleFile } from "@react95/icons/esm/react/RecycleFile"
import { Explorer102 } from "@react95/icons/esm/react/Explorer102"

import { Msrating106 } from "@react95/icons/esm/react/Msrating106"

export const NavigationSystem = () => {

//             <Link className="navItems" to={"/portfolio"}><Button>Portfolio</Button></Link>

            //<Link className="navItems" to={"/data"}><Button>Data</Button></Link>
//             <Link className="navItems" to={"/prices"}><Button disabled>🦎 FrenGecko</Button></Link>

//             <Link className="navItems" to={"/old-trade"}><Button>📉 Trade (old)</Button></Link>

//                                  <Link className="navItems" to={"/profile"}><Button disabled><Ulclient1002 variant="32x32_4"/><span>Profile</span></Button></Link>

//             <Link className="navItems" to={"/cipher"}><Button><Fontext3 variant="32x32_4"/>Cipher</Button></Link>


    return(
        <AppBar className="appBarNav">
            
            <Toolbar style={{ justifyContent: 'space-between' }}>

            <div className="navItems">

            <Link className="navItems" to={"/"}>
                <Button><User1 variant="32x32_4"/><span> Home</span></Button>
            </Link>

            <Link className="navItems" to={"/trade"}><Button><Progman20 variant="32x32_4"/> <span> Trade</span></Button></Link>
            <Link className="navItems" to={"/threethree"}><Button><Wab321019 variant="32x32_4"/><span>3,3</span></Button></Link>
            <Link className="navItems" to={"/burn"}><Button><RecycleFile variant="32x32_4"/> Burn</Button></Link>
            <Link className="navItems" to={"/wrap"}><Button><Explorer102 variant="32x32_4"/> Wrap</Button></Link>
            <Link className="navItems" to={"/chat"}><Button><Mapi32501 variant="32x32_4" aria-label="Icone de uma lupa." /> Chat</Button></Link>
            <Link className="navItems" to={"/words"}><Button><Fontext3 variant="32x32_4"/>Words.art</Button></Link>
            

            <Link className="navItems" to={"/starsarena"}><Button><Msrating106 variant="32x32_4"/><span>Stars Arena</span></Button></Link>
 
            </div>

            <div style={{ position: 'relative', display: 'inline-block' }}>
            
            </div>

            <ConnectButton />

            </Toolbar>
        </AppBar>
    )
}
import axios from "axios"
import { useState } from "react"
import { Button, TextInput, Window, WindowHeader } from "react95"
import { ActivityItem } from "./ActivityItem"



export const ActivityForAddress = () => {

        // we want to call the API

        const [address, setAddress] = useState("")

        const handleUserChange = (event) => {
            const {value,name} = event.target
            setAddress(value)
        }
    
        const [result, setResult] = useState({})

        const onSearch = () => {

        axios({
            method: "GET", 
            url: "https://prod-api.kosetto.com/holdings-activity/" + address
        }).then(response => {
            console.log(response.data['events'])
            setResult(response.data["events"])
        }).catch(error => {
            console.log(error)
        })

    }

    if (result[0] == undefined) {
        return(
            <Window className="activity">
                <WindowHeader>Wallet Activity</WindowHeader>
                <TextInput
                    value={address}          
                    placeholder='Enter Address ...'
                    onChange={handleUserChange}
                    />
                    <br></br>
                    <Button onClick={onSearch}>Search for User</Button>
                
    
    
            </Window>
        )
    
    } else {
        return(
            <Window className="activity">
                <WindowHeader>Wallet Activity</WindowHeader>
                <TextInput
                    value={address}          
                    placeholder='Enter Address ...'
                    onChange={handleUserChange}
                    />
                    <br></br>
                    <Button onClick={onSearch}>Search for User</Button>
    
                    {result.map(item => {

                        return(
                            <ActivityItem item={item} />
                        )
                        })}
    
            </Window>
        )

    }


}
import { useEffect, useState } from "react"
import { Anchor, Hourglass, ScrollView, Separator, TextInput, Window, WindowContent, WindowHeader } from "react95"
import { useGetStarShareBalance } from "../hooks/useGetStarShareBalance"
import { formatEther } from "viem"
import { SimpleTicketBuy } from "../components/SimpleTicketBuy"
import axios from "axios"
import { API } from "../contracts/API"
import { SimpleTicketSell } from "../components/SimpleTicketSell"
import { TradeSearch } from "../components/TradeSearch"
import { ArenaSearch } from "../components/ArenaSearch"
import { useAccount } from "wagmi"
import { useGetTicketPriceOfAddressWithAmount } from "../hooks/useGetTicketPriceOfAddressWithAmount"
import { useGetSellPriceOfAddressWithAmount } from "../hooks/useGetSellPriceOfAddressWithAmount"
import { useGetTicketSellPriceOfAddressWithAmount } from "../hooks/useGetTicketSellPriceWithAmount"
import { TicketTable } from "../components/TicketTable"

export const StarsArena = () => {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()

    const [selectedStarShare, setSelectedStarShare] = useState("0x71F7d4A601D74A1e1f3571139F38AdCa423B4d04")
    const [selectedTwitterHandle, setSelectedTwitterHandle] = useState("0xsubmerged")
    const [selectedUserId, setSelectedUserId] = useState("41cf6676-e77b-4b70-9b2d-70168fba9d4d")

    const [amount, setAmount] = useState(1)

    const [userData, setUserData] = useState()
    const [loadingUser, setLoadingUser] = useState(true)
    useEffect(() => {
        setLoadingUser(true)
        axios({
            method: 'get',
            url: API + "get_star_user/" + selectedTwitterHandle
        }).then(response => {
            console.log(response.data)
            setUserData(response.data["user"])
            setSelectedStarShare(response.data["user"]["address"])
            setSelectedUserId(response.data["user"]["id"])
            setLoadingUser(false)
        }).catch(error => {
            console.log(error)
        }) 

    },[selectedTwitterHandle])

    let balance = useGetStarShareBalance(selectedStarShare, address)

    const [tradeAmount, setTradeAmount] = useState(1)
    const handleTradeAmountChange = (event) => {
        const {value,name} = event.target
        setTradeAmount(value)
    }


    const [searchTarget, setSearchTarget] = useState("")
    const handleSearchUserChange = (event) => {
        const {value,name} = event.target
        setSearchTarget(value)
    }

    const [userStats,setUserStats] = useState()
    useEffect(() => {
        axios({
            method: 'get',
            url: API + "get_star_user_stats/" + selectedUserId
        }).then(response => {
            console.log(response.data)

            if(response.data['message'] == "Too Many Requests") {
                console.log("erro")
            } else {
                setUserStats(response.data)
            }

        }).catch(error => {
            console.log(error)
        }) 

    },[selectedUserId])



    const [searchResult, setSearchResult] = useState()
    useEffect(() => {
        axios({
            method: 'get',
            url: API + "search_for_star_user/" + searchTarget
        }).then(response => {
            console.log(response.data)
            setSearchResult(response.data["users"])
        }).catch(error => {
            console.log(error)
        }) 

    },[searchTarget])

    return(
        <div className="Main">

            <div className="row">



                <div className="col-sm-3">
                    <Window className="fullSize">
                        <WindowHeader>Selected User</WindowHeader>
                        <WindowContent>

                        {loadingUser ? (
                            <Hourglass size={32} style={{ margin: 20 }} />
                        ) : (
                            <div className="row">
                            <div className="col-sm-3">
                                <img src={userData["twitterPicture"]} width="90%" className="imageSmol"/>
                            </div>
                            <div className="col-sm-9">
                                <p>{userData['twitterName']}  </p>
                                <Anchor href={"https://twitter.com/" + userData["twitterHandle"]} target="_blank">
                                 ({userData["twitterHandle"]})
                                </Anchor>
                                <p>Followers: {userData["followerCount"]}</p>
                                <p>Twitter Followers: {userData["twitterFollowers"]}</p>
                            </div>
                        </div>
                        )}


                        </WindowContent>
                    </Window>

                    <br></br>
                    <br></br>

                    <Window className="fullSize">
                        <WindowHeader>Stats.exe</WindowHeader>
                        <WindowContent>

                            {userStats != undefined ? (
                                <>
                            <p>Holdings: {userStats['totalHoldings']}</p>
                            <p>Portfolio Value: {formatEther(userStats['portfolioValue'])} AVAX</p>

                            <p>Holders: {userStats['totalHolders']}</p>

                            {userStats['stats'] != undefined ? (
                                <>
                            <p>Supply: {userStats['stats']['supply']}</p>

                        <p><span className="">Buys:</span> {userStats['stats']['buys']}</p>
                        <p><span className="">Sells:</span> {userStats['stats']['sells']}</p>

                            <Separator />
                            <p><span className="">Fees Earned:</span> {formatEther(userStats['stats']['feesEarned'])} AVAX</p>
                            <p><span className="">Fees Paid:</span> {formatEther(userStats['stats']['feesPaid'])} AVAX</p>
                            <p><span className="">Total Volume:</span> {formatEther(userStats['stats']['volume'])} AVAX</p>


                                </>
                            ) : (<p></p>)}
                                </>

                            ) : (
                                <p>Loading</p>
                            )}




                        </WindowContent>
                    </Window>
                        


                </div>
                <div className="col-sm-2">
                    <Window className="fullSize">
                        <WindowHeader>Trade.exe</WindowHeader>
                        <WindowContent>

                                <div className="row">
                                    <div className="buy col-sm-6 blue">
                                        <span className="smallGray">Buy Price</span>
                                        <p>{formatEther(useGetTicketPriceOfAddressWithAmount(selectedStarShare, 1))} AVAX</p>
                                    </div>
                                    <div className="sell col-sm-6 red">
                                    <span className="smallGray">Sell Price</span>
                                    <p className="">{formatEther(useGetTicketSellPriceOfAddressWithAmount(selectedStarShare, 1))} AVAX</p>
                                    </div>
                                </div>


                                <p>Amount:</p>
                                <TextInput
                                value={tradeAmount}          
                                placeholder='1'
                                onChange={handleTradeAmountChange}
                                type="number"
                                />

                            <p>Balance: {balance}</p>

                            <SimpleTicketBuy amount={tradeAmount} address={selectedStarShare} />
                            <SimpleTicketSell address={selectedStarShare} amount={tradeAmount} />
                            <br></br>
                        </WindowContent>
                    </Window>

                </div>

                <div className="col-sm-3">
                    <Window className="fullSize">
                        <WindowHeader>Search.exe</WindowHeader>
                        <WindowContent>
                            <TextInput
                                value={searchTarget}          
                                placeholder='Enter Name ...'
                                onChange={handleSearchUserChange}
                                />

                        <ScrollView style={{ height: '7.5rem' }}>
                        <ul>
                            <ArenaSearch setSelectedTwitterHandle={setSelectedTwitterHandle} data={searchResult}  />          
                        </ul>
                        </ScrollView>



                        </WindowContent>
                    </Window>
                </div>
                            
                <div className="col-sm-4">
                    <TicketTable setSelectedTwitterHandle={setSelectedTwitterHandle}  />    
                </div>



            </div>

        </div>
    )
}
import { useState } from "react"

export const useStoreAuthKey = () =>  {

    // - [ ] getAuthKey
    // - [ ] storeAuthKey
    // - [ ] deleteAuthKey

    const getAuthKey = () => {
        const authKey = localStorage.getItem("authKey")
        return authKey
    }

    const [authKey, setAuthKey] = useState(getAuthKey())

    const addAuthKey = (NewaAuthKey) => {
        // just add new one and replace the existing one
        localStorage.setItem("authKey", NewaAuthKey)
    }

    const removeAuthKey = () => {
        localStorage.removeItem('authKey')
    }

    return{
        getAuthKey,
        setAuthKey: addAuthKey,
        removeAuthKey,
        authKey
    } 


}
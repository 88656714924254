// gets the price of a share in eth, converted from wei
// need to call the following function - getBuyPriceAfterFee

import { useContractRead } from "wagmi";
import { CipherAddress } from "../../contracts/cipherAddress";
import { CipherABI } from "../../contracts/cipherABI";

export const useCoreBuyPrice = (address, amount) => {
    
    const { data, isError, isLoading, error } = useContractRead({
        address: CipherAddress,
        abi: CipherABI,
        functionName: 'getBuyPriceAfterFee',
        args: [address, amount],
        watch: true,
        chainId: 42161
      })


      return Number(data)

}
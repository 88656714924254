import { useEffect, useState } from "react"
import { Button, Table, TableBody, TableHead, TableHeadCell, TableRow, TextInput, Window, WindowHeader } from "react95"
import { CellBody } from "./CellBody"

import { useWatchlist } from "../hooks/useWatchlist"


export const Watchlist = () => {

    const { watchlist, setWatchlists } = useWatchlist();

    // <WatchlistItems watchlist={newestWatchlist}/>

    if(watchlist == undefined) {
        // watchlist is empty 
        return(
            <center>
            <Window>
                <p>Watchlist Empty</p>
            </Window>
            </center>
        )
    }

    // watchlist.map(function(item)
    //                     <CellBody data={singleItem} keyItem1={key} />
  
//             {Object.keys(watchlist).forEach(index => {

//                let singleItem = watchlist[index]

//                console.log(singleItem)

//                return(<CellBody data={singleItem} keyItem1={index} />)

//            })}


//               <TableHeadCell>CLEAR</TableHeadCell>


    return (
        <center>
        <Window className="window">
      <WindowHeader>Watchlist.exe</WindowHeader>

            <Table>
            <TableHead>
            <TableRow>
              <TableHeadCell>NAME</TableHeadCell>
              <TableHeadCell>BUY</TableHeadCell>
              <TableHeadCell>SELL</TableHeadCell>
              <TableHeadCell>BALANCE</TableHeadCell>
              <TableHeadCell>COMMANDS</TableHeadCell>

            </TableRow>
          </TableHead>

            <TableBody>

            {watchlist.map(function(item) {
                return(<CellBody data={item} keyItem1={1} />)
            
            })}


            </TableBody>
            </Table>
            
        </Window>
        </center>
    )


}
import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Separator, Window, WindowContent, WindowHeader } from "react95"
import { API } from "../contracts/API"
import { NewUserItem } from "./NewUserItem"

export const TopWordAccounts = (props) => {

    const [newUsers, setNewUsers] = useState()

    useEffect(() => {
        axios({
                method: 'get',
                url: API + "word/get_top_users"
            }).then(response => {
                console.log(response.data)
                setNewUsers(response.data)

            }).catch(error => {
                console.log(error.response)
            })
    },[])


    return(
        <Window className="fullSize">
            <WindowHeader>Top_Words.exe</WindowHeader>
            <WindowContent>

                {newUsers == undefined ? (
                    <p>Loading....</p>
                ) : (
                    <div>
                        {newUsers.map(user => {
                            
                            return(
                                <div className="wordRow">
                                    <NewUserItem setSelectedWordText={props.setSelectedWordText} setSelectedWord={props.setSelectedWord} user={user}/>
                                </div>
                            )
                        })}
                        
                    </div>
                )}


            </WindowContent>
        </Window>


    )
}
import { useContractWrite } from "wagmi"
import { ContractAddress } from "../contracts/contractAddress"
import { ContractABI } from "../contracts/contractABI"
import { Anchor, Button } from "react95"
import { useGetPriceOfAddress } from "../hooks/useGetPriceOfAddress"
import { StarsContract } from "../contracts/starsContract"
import { useGetTicketPriceOfAddressWithAmount } from "../hooks/useGetTicketPriceOfAddressWithAmount"

export const SimpleTicketBuy = (props) => {

    let address = props.address
    let amount = props.amount

    let value = useGetTicketPriceOfAddressWithAmount(address, amount).toString()

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: StarsContract,
        abi: ContractABI,
        functionName: 'buyShares',
        args: [address, amount],
        value: value,
        chainId: 43114
      })

      const runFunction = () => {
        write()
        props.setUpdate(props.update + 1)
      }

// https://basescan.org/tx/0xbb783ec9f97646a8b256fd32bc601669399f844e35caa378d9bf22589c056494

    return (
            <Button className="smallButton" size="lg" onClick={() => write() }>BUY</Button>
        )    
    }

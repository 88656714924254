import axios from "axios"
import { useEffect, useState } from "react"
import { Anchor, Button, Separator, TextInput, Window, WindowHeader } from "react95"
import { API } from "../contracts/API"
import { ArenaSearch } from "./ArenaSearch"
import moment from "moment"

export const TicketTable = (props) => {

    // filter through new accounts quickly 
    // auto update paramaters 

    const [minTwitterFollowers, setMinTwitterFollowers] = useState(10)
    const [limit, setLimit] = useState(15)

    const [returnData, setReturnData] = useState()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        const interval = setInterval(() => {
            axios({
                method: 'get',
                url: API + "filter_new_accounts/" + minTwitterFollowers + "/" + limit
            }).then(response => {
                console.log(response.data)
                setReturnData(response.data)     
                setLoading(false)   
            }).catch(error => {
                console.log(error)
            }) 
    
        },2000)
        return() => clearInterval(interval)

    },[minTwitterFollowers])

    const handleMinTwitterFollowers = (event) => {
        const {value,name} = event.target
        setMinTwitterFollowers(value)
    }

    return(
        <Window className="fullSize">
            <WindowHeader>Query_Users.exe</WindowHeader>
                <span className="smallGray">Updates automatically every 2 seconds</span> 
                                
                <p>Min. Twitter Followers</p>
                <TextInput
                value={minTwitterFollowers}          
                placeholder='10'
                onChange={handleMinTwitterFollowers}
                type="number"
                />

                {loading ? (<span>Loading...</span>) : <span></span>}

                <br></br>

                {returnData != undefined ? (
                    <>
                    {returnData['data'].map(user => {

                        return(
                            <li className="ticket">
                            <img src={user["twitterPicture"]} width="15em" className="imageSmol smallGray"/>
                            <Anchor href={"https://twitter.com/" + user["twitterHandle"]} target="_blank">
                                {user["twitterHandle"]} 
                            </Anchor>
                            <span className="smallGray">{moment(user["createdOn"]).fromNow()}</span>
                            <span className="smallGray">(followers: {user["twitterFollowers"]})</span>
                            <Button className="right" onClick={() => props.setSelectedTwitterHandle(user["twitterHandle"])}  size="sm">Select</Button>

                        </li>

                            )
                })}

                    </>
                ) : (
                    <>
                    </>
                )}


        </Window>
    )

}
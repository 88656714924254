import { useDebugValue } from "react"
import { Anchor, Button } from "react95"

export const TradeSearch = (props) => {

    let data = props.data

    if(data[0] != undefined) {
        return(
            <div className="tradeSearch">

                {data.map(user => {

                    return(
                        <li>
                            <img src={user["twitterPfpUrl"]} width="15em" className="imageSmol"/>
                            <Anchor href={"https://twitter.com/" + user["twitterUsername"]} target="_blank">
                                {user["twitterUsername"]} ({user["twitterUsername"]})
                            </Anchor>

                            <Button onClick={() => props.setSelectedUser(user["address"])} className="miniButton" size="sm">Select</Button>

                        </li>
                    )
                })}


            </div>
        )
    } else {
        return(
            <div>
                <p>Search for something</p>
            </div>
        )
    }
    

}
// gets the price of a share in eth, converted from wei
// need to call the following function - getBuyPriceAfterFee

import { useContractRead } from "wagmi";
import { ContractABI } from "../contracts/contractABI";
import { ContractAddress } from "../contracts/contractAddress";
import { formatGwei, formatEther } from 'viem'
import { StarsContract } from "../contracts/starsContract";


export const useGetTicketPriceOfAddressWithAmount = (address, amount) => {
    
    const { data, isError, isLoading, error } = useContractRead({
        address: StarsContract,
        abi: ContractABI,
        functionName: 'getBuyPriceAfterFee',
        args: [address, amount],
        watch: true,
        chainId: 43114
      })


      return Number(data)

}
import { MenuList, MenuListItem, Separator, styleReset } from 'react95';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import original from 'react95/dist/themes/original';

import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
  ConnectButton,
  connectorsForWallets
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  arbitrum,
  avalanche,
  base
} from 'wagmi/chains';

import { injectedWallet, metaMaskWallet, rabbyWallet, rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';


import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { BuyShareFromAddress } from './components/BuyShareFromAddress';
import { PriceModal } from './components/PriceModal';
import { SellShareFromAddress } from './components/SellShareFromAddress';
import { Dapp } from './components/Dapp';
const { chains, publicClient } = configureChains(
  [base, avalanche, arbitrum],
  [
    // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
    publicProvider()
  ]
);

let projectId = "fb6c3edd1d475f8059eb4711a48113ae"
const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      metaMaskWallet({projectId, chains}),
      rabbyWallet({projectId, chains})
    ],
  },
]);



//const { connectors } = getDefaultWallets({
  //appName: 'Tinybasefrogs',
  //projectId: 'fb6c3edd1d475f8059eb4711a48113ae',
  //chains,
//});



const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  
})

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body, input, select, textarea {
    font-family: 'ms_sans_serif';
  }
`;


function App() {

  return (
    <div>
    <WagmiConfig config={wagmiConfig}>

      <GlobalStyles />
      <ThemeProvider theme={original}>
        <RainbowKitProvider chains={chains}>
          <Dapp />
        </RainbowKitProvider>
        </ThemeProvider>

    </WagmiConfig>
    </div>

    );
}

export default App;

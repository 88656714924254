import axios from "axios"
import { useEffect, useState } from "react"
import { Separator, Window, WindowContent, WindowHeader } from "react95"
import { API } from "../contracts/API"
import { NewUserItem } from "./NewUserItem"
import { useAccount } from "wagmi"

export const NewlyCreatedWords = (props) => {

    const [newUsers, setNewUsers] = useState()
    const [refreshing, setRefreshing] = useState(false)

    useEffect(() => {

        const interval = setInterval(() => {
            setRefreshing(true)
            axios({
                method: 'get',
                url: API + "word/get_new_users"
            }).then(response => {
                console.log(response.data)
                setNewUsers(response.data)
                setRefreshing(false)

            }).catch(error => {
                console.log(error.response)
            })
        }, 7000) 
        return() => clearInterval(interval)

    },[])


    return(
        <Window className="fullSize">
            <WindowHeader>New_Words.exe</WindowHeader>
            <WindowContent>

                {refreshing ? (
                    <p>Refreshing every 7 seconds...</p>
                ) : (<p>
                    <br></br>
                </p>)}

                <Separator />

                {newUsers == undefined ? (
                    <p>Loading....</p>
                ) : (
                    <div>
                        {newUsers.map(user => {
                            
                            return(
                                <div className="wordRow">
                                    <NewUserItem setSelectedWordText={props.setSelectedWordText} setSelectedWord={props.setSelectedWord} user={user}/>
                                </div>
                            )
                        })}
                        
                    </div>
                )}


            </WindowContent>
        </Window>
    )
}
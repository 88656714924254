import { Anchor, Button } from "react95"
import { wordABI } from "../contracts/wordABI"
import { wordAddress } from "../contracts/wordAddress"
import { useContractWrite } from "wagmi"
import { useGetWordBuyPriceOfAddress } from "../hooks/useGetWordBuyPrice"

export const SimpleWordBuy = (props) => {

    let amount = props.amount
    let subject = props.subject

    let value = useGetWordBuyPriceOfAddress(subject)

    const { data, isLoading, isSuccess, write } = useContractWrite({
        address: wordAddress,
        abi: wordABI,
        functionName: 'buyItem',
        args: [subject, amount],
        value: value.toString()
      })

      return (
        <>
            <Button size="sm" onClick={() => write()}>BUY</Button>
            {isSuccess && 
            <Anchor href={"https://basescan.org/tx/" + JSON.stringify(data)} target="_blank">Transaction</Anchor> 
            }
        </>
    )


}